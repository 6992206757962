import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { ReferenceAssociationDTO } from '../reference-association.dto';

export const ReferencesAssociationsResponseDTO = ResponseRows(
  ReferenceAssociationDTO
);
export type ReferencesAssociationsResponseDTO = z.infer<
  typeof ReferencesAssociationsResponseDTO
>;

import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartGeographyFeatureDTO } from '../models/report-part-geography-feature.dto';

export const PaginatedReportPartGeographyFeaturesDTO = PaginatedResponseRows(
  ReportPartGeographyFeatureDTO
);

export type PaginatedReportPartGeographyFeaturesDTO = z.infer<
  typeof PaginatedReportPartGeographyFeaturesDTO
>;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllGeoJSON } from '@turf/turf';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import { forkJoin, from, Observable, of, Subscriber, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ResponseRows } from '../models/response-rows';
import { TransectFile } from '../models/transect-file';
import { KmlHelperService } from './kml-helper.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private readonly baseEndPoint: string = `${environment.apiUrl}/files`;

  constructor(private http: HttpClient, private kmlHelper: KmlHelperService) {}

  getFileUrl(
    fileId: string,
    params?: { expiresInDays?: number }
  ): Observable<{ signedUrl: string }> {
    return this.http.get<{ signedUrl: string }>(
      `${this.baseEndPoint}/${fileId}/url`,
      { params }
    );
  }

  uploadFile(
    signedUploadUrl: string,
    file: File,
    contentType?: string
  ): Observable<void> {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    const fileData$ = new Observable(
      (observer: Subscriber<string | ArrayBuffer>): void => {
        fileReader.onload = (ev): void => {
          observer.next(ev.target.result);
          observer.complete();
        };

        fileReader.onerror = (error): void => {
          observer.error(error);
        };
      }
    );

    return fileData$.pipe(
      switchMap((data) => {
        return this.http.put<void>(signedUploadUrl, data, {
          headers: {
            'content-type': file.type || contentType,
          },
        });
      })
    );
  }

  getSignedUrl(
    originalname: string,
    mimetype: string,
    size: number,
    purpose: string,
    relation_type?: string,
    relation_id?: string
  ): Observable<TransectFile> {
    return this.http.post<TransectFile>(this.baseEndPoint, {
      originalname,
      mimetype,
      size,
      purpose,
      relation_type,
      relation_id,
    });
  }

  updateFile(file: TransectFile): Observable<TransectFile> {
    return this.http.put<TransectFile>(
      `${this.baseEndPoint}/${file._id}`,
      file
    );
  }

  updateFileRelation(
    fileId: string,
    relationType: string,
    relationId: string
  ): Observable<TransectFile> {
    return this.http.put<TransectFile>(`${this.baseEndPoint}/${fileId}`, {
      relations: {
        _id: relationId,
        type: relationType,
      },
    });
  }

  uploadKML(file: File, projectId: string): Observable<AllGeoJSON> {
    if (file) {
      return this.getSignedUrl(
        file.name,
        file.type,
        file.size,
        'project-import',
        'project',
        projectId
      ).pipe(
        switchMap((transectFile: TransectFile) => {
          let fileData$: Observable<string | ArrayBuffer> = of(null);
          if (transectFile) {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileData$ = new Observable(
              (observer: Subscriber<string | ArrayBuffer>): void => {
                fileReader.onload = (ev): void => {
                  observer.next(ev.target.result);
                  observer.complete();
                };

                fileReader.onerror = (error): void => {
                  observer.error(error);
                };
              }
            );
          }
          return forkJoin([of(transectFile), fileData$]);
        }),
        switchMap(([transectFile, fileData]) => {
          let uploadFile$: Observable<any> = of(null);
          if (transectFile) {
            uploadFile$ = this.http.put(
              transectFile.signedUploadUrl,
              fileData,
              {
                headers: {
                  'content-type': transectFile.properties.mimetype,
                },
              }
            );
          }
          return forkJoin([of(transectFile), uploadFile$]);
        }),
        switchMap(([transectFile]) => {
          let handleFile$ = of<AllGeoJSON>(null);
          if (transectFile) {
            handleFile$ = this.kmlHelper.kmlFileToFeatureCollection(file);
          }
          return handleFile$;
        }),
        switchMap((geojson) => {
          if (!geojson) {
            return throwError('Failed to process the file.');
          } else {
            return of(geojson);
          }
        })
      );
    } else {
      return of<AllGeoJSON>(null);
    }
  }

  generateUrlAndUploadFile(
    file: File,
    purpose: string,
    relation?: string,
    relationId?: string
  ): Observable<TransectFile> {
    return this.getSignedUrl(
      file.name,
      file.type,
      file.size,
      purpose,
      relation,
      relationId
    ).pipe(
      switchMap((transectFile: TransectFile) => {
        const uploadFile$ = this.uploadFile(
          transectFile.signedUploadUrl,
          file,
          transectFile.properties.mimetype
        );
        return forkJoin([of(transectFile), uploadFile$]);
      }),
      switchMap(([transectFile, uploadFile]) => {
        transectFile.status = 'uploaded';
        return this.updateFile(transectFile);
      })
    );
  }

  fetchFilesByRelation(
    relationType: string,
    relationId: string
  ): Observable<ResponseRows<TransectFile>> {
    return this.http.get<ResponseRows<TransectFile>>(this.baseEndPoint, {
      params: {
        relation_type: relationType,
        relation_id: relationId,
      },
    });
  }

  deleteFileById(fileId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseEndPoint}/${fileId}`);
  }

  downloadDeliverables(orders: { _id: string; projectName: string }[]) {
    const zip = new JSZip();

    const fetchFilesByRelation = (order: {
      _id: string;
      projectName: string;
    }): Observable<(TransectFile & { projectName: string })[]> => {
      return this.fetchFilesByRelation('report_add_ons', order._id).pipe(
        map((response) =>
          response.rows.map((row) => ({
            ...row,
            projectName: order.projectName,
          }))
        ),
        catchError((error) => {
          console.error('Error fetching files by relation:', error);
          return of([]); // Return an empty array as a fallback.
        })
      );
    };

    const fetchFileSignedUrl = (
      file: TransectFile & { projectName: string }
    ) => {
      return this.getFileUrl(file._id).pipe(
        map((url) => ({ ...file, signedUrl: url.signedUrl })),
        catchError((error) => {
          console.error('Error fetching signed URL:', error);
          return of(null);
        })
      );
    };

    const downloadFile = (
      fileWithSignedUrl: TransectFile & {
        signedUrl: string;
        projectName: string;
      }
    ) => {
      return this.http
        .get(fileWithSignedUrl.signedUrl, { responseType: 'blob' })
        .pipe(
          tap((blob) => {
            zip
              .folder(fileWithSignedUrl.projectName)
              .file(fileWithSignedUrl.name, blob);
          }),
          catchError((error) => {
            console.error('Error fetching file:', error);
            return of(null);
          })
        );
    };

    const processFileForOrder = (order: {
      _id: string;
      projectName: string;
    }) => {
      return fetchFilesByRelation(order).pipe(
        switchMap((files) => from(files)),
        switchMap((file) => fetchFileSignedUrl(file)),
        filter((fileWithUrl) => fileWithUrl !== null),
        switchMap((fileWithUrl) => downloadFile(fileWithUrl))
      );
    };

    const allFiles$ = from(orders).pipe(
      mergeMap((order) => processFileForOrder(order))
    );

    return allFiles$.pipe(
      tap(() => {
        void zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, 'deliverables.zip');
        });
      })
    );
  }
}

import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartSoilFeatureDTO } from '../models/report-part-soil-feature.dto';

export const PaginatedReportPartSoilFeaturesDTO = PaginatedResponseRows(
  ReportPartSoilFeatureDTO
);

export type PaginatedReportPartSoilFeaturesDTO = z.infer<
  typeof PaginatedReportPartSoilFeaturesDTO
>;

import { z } from 'zod';

import { DateSchema } from '../../utils/date-schema';

export const Folder = z.object({
  _id: z.string(),
  name: z.string(),
  creator__id: z.string().uuid().nullable(),
  pinned: z.boolean(),
  folderProjects: z.array(z.any()),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullable(),
});

export type Folder = z.infer<typeof Folder>;

export const CreateFolderDTO = Folder.pick({
  name: true,
}).extend({
  projectIds: z.preprocess(
    (val) => (val ? JSON.parse(val as string) : []),
    z.array(z.string()).optional()
  ),
});

export type CreateFolderDTO = z.infer<typeof CreateFolderDTO>;

export const UpdateFolderDTO = Folder.pick({
  name: true,
  pinned: true,
}).extend({
  name: z.string().optional(),
  pinned: z.boolean().optional(),
});

export type UpdateFolderDTO = z.infer<typeof UpdateFolderDTO>;

export const GetFolderQueryDTO = z.object({
  search: z.string().nullish(),
  projectId: z.string().nullish(),
  projectsScope: z.enum(['my-projects', 'company-projects']).nullish(),
});

export type GetFolderQueryDTO = z.infer<typeof GetFolderQueryDTO>;

export const GetFolderResponseDTO = z.optional(Folder);

export type GetFolderResponseDTO = z.infer<typeof GetFolderResponseDTO>;

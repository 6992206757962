import { z } from 'zod';
import { Sentiment } from '../../utils';

export const SolarPulseCountyDTO = z.object({
  name: z.string(),
  id: z.string().uuid().nullish(),
  sentiment_score: z.number(),
  sentiment: Sentiment,
  solar_regulations_score: z.number(),
  solar_regulations_sentiment: Sentiment,
  solar_news_score: z.number(),
  solar_news_sentiment: Sentiment,
  solar_velocity_score: z.number(),
  solar_velocity_sentiment: Sentiment,
});

export const SolarPulseStateDTO = z.array(
  z.object({
    state: z.string(),
    state_abbreviation: z.string(),
    geography__id: z.string(),
    counties: z.array(SolarPulseCountyDTO),
  })
);

export type SolarPulseStateDTO = z.infer<typeof SolarPulseStateDTO>;

import { z } from 'zod';
import { AssessmentLevels } from '../models/report-part-species-assessment';
export const ReportSpeciesNameAndLocationCountDTO = z.array(
  z.object({
    _id: z.string().uuid(),
    name_common: z.string().nullish(),
    location_count: z.preprocess(
      (value) => Number(value),
      z.number().nullish()
    ),
    level_public: z.nativeEnum(AssessmentLevels).nullish(),
  })
);

export type ReportSpeciesNameAndLocationCountDTO = z.infer<
  typeof ReportSpeciesNameAndLocationCountDTO
>;

import { User } from '../models/user';
import { Customer } from '../models/customer';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@transect/environments/environment';
import * as moment from 'moment';
import { RollbarService } from '../services/rollbar.service';
import Rollbar from 'rollbar';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  /**
   * Identify a user to all of the various analytics
   * services used in the application. Should be called
   * when a user first logs in or when the app is loaded
   * if the user is already logged in.
   */
  identify(user: User) {
    console.debug('Identifying user to analytics services ', user);
    const customer = (user.customers || [])[0] || {};

    try {
      this.identifyRollbar(user);
      this.identifyGoogleAnalytics(user);
      this.identifyHotjar(user, customer);
      this.identifyPendo(user, customer);
      this.identifyHubSpot(user);
    } catch (e) {
      // Identifying users to third party services should never fail.
      console.error('Failed to fully identify user to analytics ', user, e);
    }
  }

  private identifyRollbar(user: User) {
    // Rollbar is loaded through a npm package
    this.rollbar.configure({
      payload: {
        person: {
          id: user._id ?? null,
          email: user.email,
        },
      },
    });
  }

  private identifyGoogleAnalytics(user: User) {
    // Google Analytics is loaded through the index.html header
    // and uses Segment to send identity and analytics info
    if (window.analytics) {
      window.analytics.identify(user._id, {
        dimension1: user.role,
        dimension3: user._id,
        role: user.role,
        id: user._id,
        user_id: user._id,
        company: user.organization,
        title: user.title,
        firstName: user.firstname,
        lastName: user.lastname,
        name: user.fullname,
        email: user.email,
        createdAt: user.created_at,
      });
    } else {
      console.debug(
        'Skipping analytics identification for Segment and Google Analytics'
      );
    }
  }

  private identifyHotjar(user: User, customer: Partial<Customer>) {
    // Hotjar script tag is loaded through Google Tag Manger
    if (window.hj) {
      const attributes = {
        email: user.email,
        customer_id: customer._id,
        customer_name: customer.name,
        role: user.role,
        full_name: user.fullname,
        environment: environment.transectEnvironment,
        date: moment().format('X'),
        plan: user.vision_plan_name,
      };
      window.hj('identify', user._id, attributes);
    } else {
      console.debug('Skipping analytics identification for Hotjar');
    }
  }

  private identifyPendo(user: User, customer: Partial<Customer>) {
    // Pendo script tag is loaded through Google Tag Manger
    if (window.pendo) {
      const configuration = {
        visitor: {
          id: user._id,
          name: user.fullname,
          email: user.email,
          role: user.role,
        },
        account: {
          id: customer._id,
          name: customer.name,
          plan: user.vision_plan_name,
        },
      };
      window.pendo.initialize(configuration);
    } else {
      console.debug('Skipping analytics identification for Pendo');
    }
  }

  private identifyHubSpot(user: User) {
    // HubSpot script tag is loaded through Google Tag Manger
    if (window.HubSpotConversations) {
      const visitorIdentificationToken = localStorage.getItem(
        'visitor_identification_token'
      );
      if (typeof visitorIdentificationToken === 'string') {
        window.hsConversationsSettings = {
          identificationEmail: user.email,
          identificationToken: visitorIdentificationToken,
        };
      }

      window.HubSpotConversations.clear({ resetWidget: true });
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.hsConversationsOnReady = [];
          window.HubSpotConversations.clear({ resetWidget: true });
        },
      ];
    }
  }

  /**
   * Remove the user from all the various analytics services
   * used in the application. Should be called when a user
   * is logged out of the application.
   */
  deidentify() {
    console.log('Analytics deidentifying user');

    try {
      this.deidentifyRollbar();
    } catch (e) {
      // Identifying users to third party services should never fail.
      console.error('Failed to fully deidentify user to analytics ', e);
    }
  }

  deidentifyRollbar() {
    this.rollbar.configure({
      payload: {
        person: null,
      },
    });
  }
}

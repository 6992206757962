import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';

export enum AssessmentLevels {
  IGNORE = 'ignore',
  SPECIES_OF_CONCERN = 'species_of_concern',
  MAY_OCCUR = 'may_occur',
  NOT_LIKELY_TO_OCCUR = 'not_likely_to_occur',
  LIKELY_TO_OCCUR = 'likely_to_occur',
  NONE = 'none',
}

export const ReportPartSpeciesAssessment = z.object({
  _id: z.string(),
  source__id: z.string(),
  level_public: z.nativeEnum(AssessmentLevels),
  level_automated: z.nativeEnum(AssessmentLevels),
  level_reason: z.array(z.string()).default([]),
  decision_tree: z.array(z.string()).default([]),
  user_override_value: z.nativeEnum(AssessmentLevels).nullable(),
  user_override_reason: z.string().nullable(),
  user_overridden_by__id: z.string().nullable(),
  // this is the admin override value
  value: z.nativeEnum(AssessmentLevels).nullable(),
  override_reason: z.string().nullable(),
  report_part: z.object({
    _id: z.string(),
  }),
  report: z.object({
    _id: z.string(),
  }),
  created_at: DateSchema,
  updated_at: DateSchema,
});

export type ReportPartSpeciesAssessment = z.infer<
  typeof ReportPartSpeciesAssessment
>;

export const GetReportPartSpeciesAssessmentDTO = z.array(
  ReportPartSpeciesAssessment
);
export type GetReportPartSpeciesAssessmentDTO = z.infer<
  typeof GetReportPartSpeciesAssessmentDTO
>;

export const UpdateReportPartSpeciesAssessmentDTO =
  ReportPartSpeciesAssessment.partial().omit({
    report_part: true,
    report: true,
  });

export type UpdateReportPartSpeciesAssessmentDTO = z.infer<
  typeof UpdateReportPartSpeciesAssessmentDTO
>;

import { z } from 'zod';

export const PaginationControllerQuery = z
  .object({
    page: z.preprocess((value) => Number(value), z.number().gte(1)),
    pageSize: z.preprocess((value) => Number(value), z.number().gte(1)),
    quickFilter: z.string().optional(),
    sortModel: z.preprocess(
      (val) => {
        if (!val) {
          return val;
        }
        return JSON.parse(val as string);
      },
      z
        .array(
          z.object({
            colId: z.string(),
            order: z.enum(['asc', 'desc']).optional(),
          })
        )
        .optional()
    ),
  })
  .strict();

export const PaginationSpeciesLocationControllerQuery =
  PaginationControllerQuery.extend({
    type: z.enum(['federal', 'state']),
  });

export const PaginationCatalogLocationControllerQuery =
  PaginationControllerQuery.extend({
    catalogItemCategory: z.string().optional(),
    catalogItemId: z.string().uuid().optional(),
  });

export type PaginationControllerQuery = z.infer<
  typeof PaginationControllerQuery
>;

export type PaginationSpeciesLocationControllerQuery = z.infer<
  typeof PaginationSpeciesLocationControllerQuery
>;

export type PaginationCatalogLocationControllerQuery = z.infer<
  typeof PaginationCatalogLocationControllerQuery
>;

export const PaginationCatalogItemControllerQuery =
  PaginationControllerQuery.extend({
    category: z.preprocess(
      (val) => (val === 'null' ? null : val),
      z.string().optional().nullable()
    ),
  });
export type PaginationCatalogItemControllerQuery = z.infer<
  typeof PaginationCatalogItemControllerQuery
>;

export const PaginationRegulationControllerQuery =
  PaginationControllerQuery.extend({
    topic: z.string().optional(),
    childrenOf: z.string().optional(),
  });
export type PaginationRegulationControllerQuery = z.infer<
  typeof PaginationRegulationControllerQuery
>;

export const PaginationActiveRegulationControllerQuery =
  PaginationControllerQuery.extend({
    childrenOf: z.string().optional(),
  });
export type PaginationActiveRegulationControllerQuery = z.infer<
  typeof PaginationActiveRegulationControllerQuery
>;

export const PaginationSpeciesControllerQuery =
  PaginationControllerQuery.extend({
    speciesType: z.string(),
    assessmentLevel: z.string(),
    speciesId: z.string().nullish(),
  });
export type PaginationSpeciesControllerQuery = z.infer<
  typeof PaginationSpeciesControllerQuery
>;

export const PaginationReferenceControllerQuery =
  PaginationControllerQuery.extend({
    category: z.string().optional(),
  });
export type PaginationReferenceControllerQuery = z.infer<
  typeof PaginationReferenceControllerQuery
>;

import { z, ZodRawShape } from 'zod';

/**
 * Relation schema that will preprocess and convert a string uuid to an object with { _id: string }.
 * This will also make the object properties optional except for _id.
 *
 * If a relation is soft deleted, Mikro-ORM will only return the _id as a string rather than the relation object.
 *
 * @example <caption>UserDTO can be a string uuid if it was soft deleted. Using RelationSchema will preprocess the data and convert it to an object with _id property.</caption>
 * const ProjectDTO = z.object({
 *  _id: "uuid",
 *  name: "A Project",
 *  creator: UserDTO
 * });
 *
 * const ProjectDTO = z.object({
 *  _id: "uuid",
 *  name: "A Project",
 *  creator: RelationSchema(UserDTO) // Converts "user-uuid" to { _id: "user-uuid" } if soft deleted.
 * });
 *
 * @param schema
 * @returns
 */
export const RelationSchema = <T extends ZodRawShape>(
  schema: z.ZodObject<T>
) => {
  return z.preprocess((arg) => {
    if (typeof arg === 'string') {
      return { _id: arg };
    }

    return arg;
  }, schema.deepPartial().extend({ _id: z.string().uuid() }));
};

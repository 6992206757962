import { NavigationExtras, Router } from '@angular/router';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  ICellRendererParams,
  IAfterGuiAttachedParams,
} from 'ag-grid-community';
import { isFunction } from 'lodash';

type ICellRendererParamsWithClickHandler = ICellRendererParams & {
  route: (data: any) => RouteData;
  isDisabled?: (data: any) => boolean;
};

export type RouteData = {
  commands: any[];
  extras?: NavigationExtras;
};

@Component({
  selector: 'ts-ui-router-link-cell-renderer',
  templateUrl: './ui-router-link-cell-renderer.component.html',
  styleUrls: ['./ui-router-link-cell-renderer.component.scss'],
})
export class UiRouterLinkCellRendererComponent
  implements ICellRendererAngularComp
{
  params?: ICellRendererParamsWithClickHandler;

  route?: RouteData;

  disabled = false;

  constructor(private router: Router) {}

  agInit(params: ICellRendererParamsWithClickHandler): void {
    this.params = params;
    if (isFunction(this.params?.route)) {
      this.route = this.params.route(params.data);
    }

    const isDisabled = params.isDisabled;

    if (isDisabled !== undefined) {
      this.disabled = isDisabled(params.data) ?? false;
    }
  }

  go(event: MouseEvent): void {
    if (this.route) {
      if (event.metaKey || event.ctrlKey) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(this.route.commands, this.route.extras)
        );
        window.open(url, '_blank');
      } else {
        void this.router.navigate(this.route.commands, this.route.extras);
      }
    } else {
      console.error('Route is undefined.');
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    return;
  }

  refresh(params: any): boolean {
    return true;
  }
}

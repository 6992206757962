import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { from, Observable, of, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

export interface OptEventParams {
  label?: string;
  value?: number | string;
  user__id?: string;
  association__id?: string;
  metadata?: object | null;
}

@Injectable({
  providedIn: 'root',
})
export class EventStreamService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   *
   * @param category The object that user interacted with on the page. (e.g. Video)
   * @param action The type of interaction with the object. (e.g. Play)
   * @param params Optional additional event parameters
   * @returns An Observable of the HTTPResponse for the request, with a response body in the requested type.
   */
  publish(
    category: string,
    action: string,
    params?: OptEventParams
  ): Observable<any> {
    const event = {
      category,
      action,
      ...params,
    };

    return from(this.authService.isTokenSet()).pipe(
      switchMap((isTokenSet) => {
        if (environment.TRANSECT_ENVIRONMENT !== 'local' && isTokenSet) {
          return this.http.post<void>(`${environment.apiUrl}/events`, event);
        } else {
          // eslint-disable-next-line no-console
          console.log('Skipping event stream for: ', event);
          return of(true);
        }
      })
    );
  }
}

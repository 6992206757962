import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpgradeRequestType } from '@transect-nx/data-transfer-objects';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SlackMessagesService {
  private readonly baseEndPoint: string = `${environment.apiUrl}/slack-messages`;

  constructor(private http: HttpClient) {}

  sendUpgradeRequest(
    type: UpgradeRequestType,
    reason: string
  ): Observable<void> {
    return this.http.post<void>(`${this.baseEndPoint}/send-upgrade-request`, {
      type,
      reason,
    });
  }
}

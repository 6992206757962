import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  forwardRef,
  HostBinding,
  OnDestroy,
  QueryList,
} from '@angular/core';
import {
  MatLegacyCheckbox as MatCheckbox,
  MatLegacyCheckboxChange as MatCheckboxChange,
} from '@angular/material/legacy-checkbox';
import { combineLatest, map, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'transect-nx-ui-tree-node',
  templateUrl: './ui-tree-node.component.html',
  styleUrls: ['./ui-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTreeNodeComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(forwardRef(() => UiTreeNodeComponent))
  children?: QueryList<UiTreeNodeComponent>;
  @ContentChild(MatCheckbox) checkbox: MatCheckbox | null | undefined = null;

  private withSeparator = false;
  @HostBinding('class.with-separator') get class() {
    return this.withSeparator;
  }

  expanded = false;

  private destroy = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy.next();
  }

  ngAfterContentInit(): void {
    if (this.checkbox) {
      const childrenCheckboxChanges =
        this.children
          ?.toArray()
          .filter(
            (child): child is UiTreeNodeComponent & { checkbox: MatCheckbox } =>
              child.hasCheckbox()
          )
          .map((child) =>
            child.checkbox.change.pipe(
              startWith(child.checkbox.checked),
              map((change) =>
                typeof change === 'boolean' ? change : change.checked
              )
            )
          ) ?? [];

      combineLatest(childrenCheckboxChanges)
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.determineCheckboxState();
        });

      this.checkbox.change.pipe(takeUntil(this.destroy)).subscribe((change) => {
        this.syncChildren(change);
      });
    }

    this.determineCheckboxState();
  }

  setWithSeparator(withSeparator: boolean) {
    this.withSeparator = withSeparator;

    this.children?.forEach((child) => {
      child.setWithSeparator(this.withSeparator);
    });
  }

  private syncChildren(change: MatCheckboxChange) {
    this.children
      ?.toArray()
      .filter(
        (child): child is UiTreeNodeComponent & { checkbox: MatCheckbox } =>
          child.hasCheckbox()
      )
      .forEach((child) => {
        if (change.checked !== child.checkbox.checked) {
          child.checkbox.toggle();
        }
      });
  }

  determineCheckboxState() {
    if (this.checkbox && this.hasChildren()) {
      const some = this.children?.toArray().some((child) => {
        return child.checkbox?.checked;
      });

      const all = this.children?.toArray().every((child) => {
        return child.checkbox?.checked;
      });

      if ((all && !this.checkbox.checked) || (!all && this.checkbox.checked)) {
        this.checkbox.toggle();
      }

      const indeterminate = some && !all;
      if (this.checkbox.indeterminate !== indeterminate) {
        this.checkbox.indeterminate = indeterminate;
        this.checkbox.indeterminateChange.emit(indeterminate);
      }
    }
  }

  hasChildren(): boolean {
    if (!this.children) {
      throw new Error(
        'Attempted to check children before content was rendered. Try calling this AfterContentInit.'
      );
    }

    return this.children.length > 0;
  }

  hasCheckbox(): boolean {
    if (!this.checkbox === null) {
      throw new Error(
        'Attempted to check content before init. Try calling this AfterContentInit.'
      );
    }

    return Boolean(this.checkbox);
  }
}

import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { RegulationDTO } from '../regulation-dto';

export const RegulationSearchResponseRows = PaginatedResponseRows(
  RegulationDTO.pick({
    _id: true,
    name: true,
    acronym: true,
    nameAcronymBack: true,
    customer__id: true,
  })
);

export type RegulationSearchResponseRows = z.infer<
  typeof RegulationSearchResponseRows
>;

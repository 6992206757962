<mat-expansion-panel
  class="expansion-panel"
  togglePosition="before"
  (opened)="isCommentBoxOpen.next(true)"
  (closed)="isCommentBoxOpen.next(false)"
  [expanded]="expanded$ | async"
  [disabled]="!collapsable"
>
  <mat-expansion-panel-header *ngIf="shouldDisplayHeader">
    <mat-panel-title>
      <span *ngIf="collapsable">
        {{ isCommentBoxOpen.value ? 'Hide Comments' : 'Show Comments' }}
      </span>
      <span *ngIf="!collapsable">Comments</span>
    </mat-panel-title>
    <mat-panel-description>
      <button
        mat-icon-button
        matTooltip="Scroll to Bottom"
        (click)="scrollToBottom($event)"
      >
        <mat-icon fontSet="far" fontIcon="fa-angle-double-down"></mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Refresh"
        (click)="refreshComments($event)"
      >
        <mat-icon fontSet="far" fontIcon="fa-redo-alt"></mat-icon>
      </button>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div *ngIf="!loadingComment; else skeletonLoading">
    <div
      *ngIf="comments.length > 0"
      class="comments-container"
      #commentsContainer
    >
      <div *ngFor="let comment of comments" class="comment-row">
        <div class="user-avatar">
          <ts-user-profile-photo
            [transectFile]="comment.creator?.photo_gcs"
            imgClass="comments-image"
          ></ts-user-profile-photo>
        </div>
        <div class="comment-details">
          <div class="comment-creator">
            {{ comment.creator?.fullname }}
            <time *ngIf="comment.created_at as createdAt">{{
              getFromNow(createdAt)
            }}</time>

            <ng-container *ngIf="userOrNull$ | async as user">
              <div
                *ngIf="
                  !readonly &&
                  (user?._id === comment.creator__id || user?.role === 'admin')
                "
                class="comment-actions"
              >
                <mat-spinner
                  *ngIf="comment.updatingComment || comment.deletingComment"
                  [diameter]="20"
                  [strokeWidth]="2"
                ></mat-spinner>
                <ng-container
                  *ngIf="!comment.updatingComment && !comment.deletingComment"
                >
                  <a *ngIf="!comment.isEditing" (click)="editComment(comment)">
                    Edit
                  </a>
                  <a *ngIf="comment.isEditing" (click)="updateComment(comment)">
                    Update
                  </a>
                  <a
                    *ngIf="comment.isEditing"
                    (click)="cancelEditComment(comment)"
                  >
                    Cancel
                  </a>
                  <a
                    *ngIf="!comment.isEditing"
                    (click)="deleteComment(comment)"
                  >
                    Delete
                  </a>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div
            *ngIf="!objectId && comment.objectName"
            class="comment-reference"
          >
            <em>regarding</em>
            <a
              *ngIf="comment.object__id as objectId"
              (click)="navigateToObject(objectId)"
            >
              {{ comment.objectName }}
            </a>
          </div>
          <div *ngIf="!comment.isEditing" class="comment-text">
            <span *ngIf="comment.readMore">
              {{ comment.properties?.text }}
            </span>
            <span *ngIf="!comment.readMore">{{
              comment.properties?.text | slice: 0:defaultLength
            }}</span>
            <span
              *ngIf="(comment?.properties?.text?.length ?? 0) > defaultLength"
            >
              <span *ngIf="!comment.readMore">... </span>
              <a *ngIf="!comment.readMore" (click)="comment.readMore = true"
                >[more...]</a
              >
              <a *ngIf="comment.readMore" (click)="comment.readMore = false"
                >[less...]</a
              >
            </span>
          </div>
          <mat-form-field
            *ngIf="comment.isEditing"
            class="comment-textarea"
            floatLabel="never"
          >
            <textarea
              matInput
              [(ngModel)]="comment.updatedComment"
              [disabled]="comment.updatingComment ?? false"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="comments.length === 0" class="no-comments-section">
      There are no comments at this time.
    </div>

    <div *ngIf="!readonly" class="add-comment-container">
      <mat-divider></mat-divider>
      <mat-form-field class="comment-textarea">
        <mat-label>Add a comment</mat-label>
        <textarea
          matInput
          [(ngModel)]="comment"
          [disabled]="savingComment"
        ></textarea>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        class="post-comment-btn"
        (click)="saveComment()"
        [disabled]="savingComment || !comment"
      >
        <mat-spinner
          *ngIf="savingComment"
          class="button-progress-spinner"
          [diameter]="18"
          [strokeWidth]="2"
        ></mat-spinner>
        Post Comment
      </button>
    </div>
  </div>
</mat-expansion-panel>

<ng-template #skeletonLoading>
  <br />
  <ts-skeleton-composite-loading
    [count]="8"
    [lineCount]="2"
    [lineHeight]="1.6"
  ></ts-skeleton-composite-loading>
</ng-template>

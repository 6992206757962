import { string, z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export const RegulationOverrideLogEntity = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  effectiveStatus: z.enum(['active', 'inactive']),
});

export type RegulationOverrideLogEntity = z.infer<
  typeof RegulationOverrideLogEntity
>;

export const PermitOverrideLogEntity = RegulationOverrideLogEntity.extend({});

export type PermitOverrideLogEntity = z.infer<typeof PermitOverrideLogEntity>;

export const SpeciesAssessmentLevelOverrideLogEntity = z.object({
  _id: z.string().uuid(),
  newLevel: z.string(),
  commonName: z.string().nullish(),
  previousLevel: z.string(),
  scientificName: z.string().nullish(),
});

export const ConcernLevelOverrideLogEntity = z.object({
  concernLevelName: z.string(),
});

export const ReportOverrideLogEntity = z.union([
  RegulationOverrideLogEntity,
  SpeciesAssessmentLevelOverrideLogEntity,
  PermitOverrideLogEntity,
  ConcernLevelOverrideLogEntity,
]);

export type ReportOverrideLogEntity = z.infer<typeof ReportOverrideLogEntity>;

export type SpeciesAssessmentLevelOverrideLogEntity = z.infer<
  typeof SpeciesAssessmentLevelOverrideLogEntity
>;

export const ReportOverrideLogBase = z.object({
  _id: z.string(),
  overridden_by: z.object({
    _id: z.string(),
    firstname: z.string().optional(),
    lastname: z.string().optional(),
    fullname: z.string().optional(),
    role: z.enum(['user', 'admin', 'unverified', 'public-user']).optional(),
  }),
  reason: z.string().nullable(),
  entity: ReportOverrideLogEntity.nullable(),
  entity_key: z.string(),
  created_at: DateSchema.optional(),
  updated_at: DateSchema.optional(),
  report: z
    .object({
      _id: z.string(),
    })
    .optional(),
  deleted_at: DateSchema.optional(),
});

export const RegulationStatusOverrideLog = ReportOverrideLogBase.extend({
  operation_type: z.literal('regulation-status-override'),
  entity: RegulationOverrideLogEntity,
});

export const PermitStatusOverrideLog = ReportOverrideLogBase.extend({
  operation_type: z.literal('permit-status-override'),
  entity: PermitOverrideLogEntity,
});

export const SpeciesAssessmentLevelOverrideLog = ReportOverrideLogBase.extend({
  operation_type: z.literal('species-concern-level-override'),
  entity: SpeciesAssessmentLevelOverrideLogEntity,
});

export const ConcernLevelOverrideLogBase = ReportOverrideLogBase.extend({
  entity: ConcernLevelOverrideLogEntity,
});
export const SpeciesConcernLevelOverrideLog =
  ConcernLevelOverrideLogBase.extend({
    operation_type: z.literal('species'),
  });
export const StateSpeciesConcernLevelOverrideLog =
  ConcernLevelOverrideLogBase.extend({
    operation_type: z.literal('state_species'),
  });
export const EnvironmentalComplianceConcernLevelOverrideLog =
  ConcernLevelOverrideLogBase.extend({
    operation_type: z.literal('environmental_compliance'),
  });
export const ProtectedAreaConcernLevelOverrideLog =
  ConcernLevelOverrideLogBase.extend({
    operation_type: z.literal('protected_area'),
  });
export const WatersConcernLevelOverrideLog = ConcernLevelOverrideLogBase.extend(
  {
    operation_type: z.literal('waters'),
  }
);

export const ReportOverrideLog = z.discriminatedUnion('operation_type', [
  RegulationStatusOverrideLog,
  PermitStatusOverrideLog,
  SpeciesAssessmentLevelOverrideLog,
  SpeciesConcernLevelOverrideLog,
  StateSpeciesConcernLevelOverrideLog,
  EnvironmentalComplianceConcernLevelOverrideLog,
  ProtectedAreaConcernLevelOverrideLog,
  WatersConcernLevelOverrideLog,
]);

export type ReportOverrideLog = z.infer<typeof ReportOverrideLog>;

export const GetReportOverrideLogsDTO = z.array(ReportOverrideLog);

export const GetReportOverrideLogsQueryDTO = z.object({
  report__id: z.string(),
});

export type GetReportOverrideLogsQueryDTO = z.infer<
  typeof GetReportOverrideLogsQueryDTO
>;

// could not extend the Discriminated Union ReportOverrideLog, seems like an issue with TS / Zod
export const CreateOverrideLogDTO = ReportOverrideLogBase.extend({
  operation_type: z.enum([
    'regulation-status-override',
    'permit-status-override',
    'species-concern-level-override',
    'species',
    'state_species',
    'environmental_compliance',
    'protected_area',
    'waters',
  ]),
}).omit({
  created_at: true,
  deleted_at: true,
  updated_at: true,
  _id: true,
});

export type CreateOverrideLogDTO = z.infer<typeof CreateOverrideLogDTO>;

export type GetReportOverrideLogsDTO = z.infer<typeof GetReportOverrideLogsDTO>;

import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartFloodHazardFeatureDTO } from '../models/report-part-flood-hazard-feature.dto';

export const PaginatedReportPartFloodHazardFeaturesDTO = PaginatedResponseRows(
  ReportPartFloodHazardFeatureDTO
);

export type PaginatedReportPartFloodHazardFeaturesDTO = z.infer<
  typeof PaginatedReportPartFloodHazardFeaturesDTO
>;

import { z } from 'zod';
import { ReportAddOnDTO } from '../report-add-on/report-add-on.dto';
import { MarketplaceAltaScopeDTO } from '../marketplace-product-category/marketplace-alta-scope-of-work-options.dto';

export const RequestPhase1ESAAddonQuoteBody = z.object({
  shareParcels: z.boolean(),
  couponCode: z.string().nullish(),
  reportId: z.string().uuid().nullish(),
  projectId: z.string().uuid(),
});

export type RequestPhase1ESAAddonQuoteBody = z.infer<
  typeof RequestPhase1ESAAddonQuoteBody
>;

export const FindAddOnForReportByKeyQuery = z.object({
  omitCancelledAndRejected: z
    .preprocess((val) => {
      if (val === 'true') {
        return true;
      }
      if (val === 'false') {
        return false;
      }
      return null;
    }, z.boolean().optional())
    .optional(),
});

export type FindAddOnForReportByKeyQuery = z.infer<
  typeof FindAddOnForReportByKeyQuery
>;

export const ReportAddOnByOfferingKeyResponse = ReportAddOnDTO.nullish();

export type ReportAddOnByOfferingKeyResponse = z.infer<
  typeof ReportAddOnByOfferingKeyResponse
>;

export const RequestAddOnQuoteBody = z.object({
  shareParcels: z.boolean(),
  couponCode: z.string().nullish(),
  reportId: z.string().uuid().nullish(),
  addOnOfferingKey: z.string(),
  projectId: z.string().uuid(),
  details: z.string().nullish(),
  project_type: z.string().nullish(),
  project_subtype: z.string().nullish(),
  additional_details_for_request_quote: z.string().nullish(),
  options_selected: z.union([z.string(), MarketplaceAltaScopeDTO]).nullish(),
  intercon_selected_authority: z.string().nullish(),
  desired_project_size: z.number().nullish(),
  desired_intercon_voltage: z.number().nullish(),
  intercon_inverter_ratio: z.string().default('1.20'),
  intercon_connection_type: z.enum(['transmission', 'distribution']).nullish(),
});

export type RequestAddOnQuoteBody = z.infer<typeof RequestAddOnQuoteBody>;

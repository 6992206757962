import { Injectable, NgZone } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import {
  PusherEventDTOMap,
  PusherEvents,
  TransectChannels,
} from '@transect-nx/data-transfer-objects';
import { mergeMap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  private pusher: Pusher;
  private channelsSubject$: BehaviorSubject<Map<TransectChannels, Channel>>;

  private channels$: Observable<Map<TransectChannels, Channel>>;

  constructor(private auth: AuthService, ngZone: NgZone) {
    ngZone.runOutsideAngular(() => {
      this.pusher = new Pusher(environment.pusher, {
        cluster: 'us2',
      });
    });

    const channelMap = new Map<TransectChannels, Channel>();

    channelMap.set('public', this.pusher.subscribe('public'));

    this.channelsSubject$ = new BehaviorSubject(channelMap);

    this.channels$ = this.channelsSubject$.asObservable();

    this.auth.userObserver$.subscribe((user) => {
      const channels = this.channelsSubject$.getValue();

      channels.get('user')?.unsubscribe();
      channels.get('customer')?.unsubscribe();

      channels.delete('user');
      channels.delete('customer');

      if (user) {
        if ((user.customers || []).length > 0) {
          channels.set(
            'customer',
            this.pusher.subscribe(user.customers[0]._id)
          );
        }

        channels.set('user', this.pusher.subscribe(user._id));
      }

      this.channelsSubject$.next(channels);
    });
  }

  listenToEvents$<T extends PusherEvents>(
    pusherEvent: T
  ): Observable<PusherEventDTOMap[T]> {
    return this.channels$.pipe(
      switchMap((channels) => {
        return from(
          Array.from(channels.values()).map((channel) =>
            this.createChannelObservable(channel, pusherEvent)
          )
        );
      }),
      mergeMap((event$) => event$)
    );
  }

  private createChannelObservable<T extends PusherEvents>(
    channel: Channel,
    pusherEvent: T
  ): Observable<PusherEventDTOMap[T]> {
    return new Observable<PusherEventDTOMap[T]>((subscriber) => {
      channel.bind(pusherEvent, (data: unknown) => {
        subscriber.next(
          PusherEventDTOMap[pusherEvent].parse(data) as PusherEventDTOMap[T]
        );
      });

      return () => {
        channel.unbind(pusherEvent);
      };
    });
  }
}

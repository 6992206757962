import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { UserDTO } from '../../users';
import { ReportExportsDTO } from '../report-exports-object';
export const ReportExportWithCreatorDTO = ReportExportsDTO.extend({
  creator: z.preprocess(
    (val) => (typeof val === 'string' ? { _id: val } : val),
    UserDTO.deepPartial().extend({ _id: z.string().uuid() }).nullable()
  ),
});
export const ReportExportIndexResponseRows = ResponseRows(
  ReportExportWithCreatorDTO.extend({
    creator__id: z.string().nullable(),
  })
);
export type ReportExportIndexResponseRows = z.infer<
  typeof ReportExportIndexResponseRows
>;

import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UnrecoverableStateDialogComponent } from '../components/unrecoverable-state-dialog/unrecoverable-state-dialog.component';
import { UnrecoverableStateDialogModule } from '../unrecoverable-state-dialog.module';

@Injectable({
  providedIn: UnrecoverableStateDialogModule,
})
export class UnrecoverableStateDialogService {
  private updateDialogRef: MatDialogRef<
    UnrecoverableStateDialogComponent,
    void
  >;

  constructor(private dialog: MatDialog) {}

  open(reason: string): void {
    if (!this.updateDialogRef) {
      this.updateDialogRef = this.dialog.open(
        UnrecoverableStateDialogComponent,
        {
          width: '49.6rem',
          disableClose: true,
          data: {
            reason,
          },
        }
      );
    }
  }
}

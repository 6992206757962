import { z } from 'zod';
import { ProjectStatus, ReportAddOnStatus, TagDTO } from '../../models';
import { DateAsString } from '../../utils/date-schema';
import { RelationSchema } from '../../utils/relation';
import { MonitoringStatus } from '@transect-nx/models';

export const GetProjectsMineResponseDTO = z.object({
  count: z.number(),
  rows: z
    .object({
      _id: z.string().uuid(),
      name: z.string().nullable(),
      status: z.nativeEnum(ProjectStatus).nullable(),
      owner: z.string().nullable(),
      owner_active: z.boolean().nullable(),
      created_at: DateAsString,
      project_access_count: z.number(),
      geometry: z.any(),
      has_geometry: z
        .boolean()
        .nullable()
        .transform((val) => {
          if (val == null) return false;
          return true;
        }),
      pinned: z.boolean(),
      is_official_demo: z.boolean(),
      created_from: z.string(),
      updated_at: DateAsString,
      customer__id: z.string().uuid().nullable(),
      properties: z
        .object({
          warning: z.string(),
        })
        .deepPartial()
        .nullish(),
      context: z
        .object({
          construction_type: z.string(),
          expert_review: z.string(),
          federal_funds: z.string(),
          plan_to_impact_waters: z.string(),
          project_type: z.union([z.string(), z.object({}).passthrough()]),
        })
        .deepPartial(),
      reports: z
        .object({
          _id: z.string().uuid(),
          type: z.string(),
          status: z.string(),
          created_at: DateAsString,
          concern_levels: z.any().nullish(),
          monitor_new_changes_count: z.number().nullable(),
          monitoring_statuses: z.array(
            z.object({
              active: z.boolean(),
              user: z.string().uuid(),
              report: z.string().uuid(),
            }) as z.ZodType<MonitoringStatus>
          ),
          properties: z
            .object({
              aoi: z
                .object({
                  area: z
                    .object({
                      acres: z.number().optional(),
                    })
                    .optional(),
                })
                .optional(),
            })
            .nullish(),
          details: z
            .object({
              parcel: z.object({
                countAoi: z.number().nullish(),
                custom: z
                  .object({
                    ownerInfo: z
                      .object({
                        distinctOwnerCount: z.number(),
                      })
                      .nullish(),
                  })
                  .nullish(),
              }),
              regulation_location: z.object({
                custom: z
                  .object({
                    count_permits: z.number().nullish(),
                  })
                  .nullish(),
              }),
            })
            .nullish(),
        })
        .array(),
      project_accesses: z
        .object({
          _id: z.string().uuid(),
          role: z.string(),
          preferences: z.any(),
        })
        .array(),
      report_add_ons: z
        .object({
          _id: z.string().uuid(),
          status: z.nativeEnum(ReportAddOnStatus),
          report_add_on_offering: z.object({
            name: z.string(),
            _id: z.string().uuid(),
          }),
        })
        .array()
        .nullish(),
      geographies_states: z
        .object({
          abbreviation: z.string(),
          state: z.string(),
          geography__id: z.string().uuid(),
          counties: z.string().array().optional(),
        })
        .array()
        .nullable(),
      customer: RelationSchema(
        z.object({
          _id: z.string().uuid(),
          name: z.string(),
          all_collaborator: z.boolean(),
        })
      ).nullable(),
      project_role: z
        .enum(['owner', 'collaborator', 'viewer', 'tester'])
        .nullable(),
      first_state: z.string().nullable(),
      first_county: z.string().nullable(),
      first_report_status: z.string().nullable(),
      in_united_states: z.boolean().nullable(),
      public: z.boolean().default(false),
      tags: z.array(TagDTO.pick({ _id: true, name: true })).optional(),
      creator: z
        .preprocess(
          (val) => (typeof val === 'string' ? { fullName: 'Unknown' } : val),
          z.object({ fullName: z.string() })
        )
        .nullish(),
    })
    .array(),
});

export type GetProjectsMineResponseDTO = z.infer<
  typeof GetProjectsMineResponseDTO
>;

export type GetProjectsMineResponseInputDTO = z.input<
  typeof GetProjectsMineResponseDTO
>;

import { z } from 'zod';
import { stringToNumber } from '../../../utils/string-to-number';

export const ReportsMineQueryDTO = z.object({
  page: z
    .preprocess(
      (val) => (typeof val === 'string' ? stringToNumber(val) : val),
      z.number()
    )
    .default(1),
  pageSize: z
    .preprocess(
      (val) => (typeof val === 'string' ? stringToNumber(val) : val),
      z.number()
    )
    .default(100),
  sortBy: z.string().default('created_at'),
  order: z
    .string()
    .transform((value) => value.toUpperCase())
    .default('DESC'),
  user__id: z.string().uuid().nullish(),
  project__id: z.string().uuid().nullish(),
  type: z.string().nullish(),
  is_sales_demo: z
    .preprocess(
      (val) => (typeof val === 'string' ? val === 'true' : val),
      z.boolean()
    )
    .optional(),
});

export type ReportsMineQueryDTO = z.infer<typeof ReportsMineQueryDTO>;

import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartEcoregionFeatureDTO } from '../models/report-part-ecoregion-feature.dto';

export const PaginatedReportPartEcoregionFeaturesDTO = PaginatedResponseRows(
  ReportPartEcoregionFeatureDTO
);

export type PaginatedReportPartEcoregionFeaturesDTO = z.infer<
  typeof PaginatedReportPartEcoregionFeaturesDTO
>;

import { z } from 'zod';

import {
  DateFilter,
  SetFilterBase,
  TextFilter,
  StringSetFilter,
} from '../../utils/aggrid-filters';
import { sortModel } from '../../utils/paginated-response-rows';
import { jsonSchema } from '../../utils/string-to-json';
import { safeNumber } from '../../utils/string-to-number';
import { ReportAddOnDTO, ReportAddOnStatus } from './report-add-on.dto';

export const ReportAddOnStatusFilter = SetFilterBase.extend({
  values: z.array(z.nativeEnum(ReportAddOnStatus)),
});

export const ReportAddOnIndexFilterModel = jsonSchema(
  z.object({
    status: ReportAddOnStatusFilter.nullish(),
    'project.geographies_states.state': StringSetFilter.nullish(),
    'report_add_on_offering.name': TextFilter.nullish(),
    contractor: TextFilter.nullish(),
    sent_to_contractor_on: DateFilter.nullish(),
    'project.name': TextFilter.nullish(),
    'project.customer.name': StringSetFilter.nullish(),
  })
);

export const ReportAddOnIndexSortModel = sortModel;

export const ReportAddOnAdminIndexQuery = z.object({
  filterModel: ReportAddOnIndexFilterModel.nullish(),
  sortModel: ReportAddOnIndexSortModel.nullish(),
  page: safeNumber.nullish(),
  pageSize: safeNumber.nullish(),
  omitCancelledAndRejected: z
    .preprocess((val) => {
      if (val === 'true') {
        return true;
      }
      if (val === 'false') {
        return false;
      }
      return null;
    }, z.boolean().nullish())
    .nullish(),
});

export const ReportAddOnIndexQuery = ReportAddOnAdminIndexQuery.extend({
  reportId: z.string().uuid(),
  key: z.string().uuid().nullish(),
  status: z.nativeEnum(ReportAddOnStatus).nullish(),
  reportAddOnOfferingKey: z.string().nullish(),
});

export type ReportAddOnIndexQuery = z.infer<typeof ReportAddOnIndexQuery>;
export type ReportAddOnAdminIndexQuery = z.infer<
  typeof ReportAddOnAdminIndexQuery
>;

export type ReportAddOnIndexFilterModel = z.infer<
  typeof ReportAddOnIndexFilterModel
>;
export type ReportAddOnIndexSortModel = z.infer<
  typeof ReportAddOnIndexSortModel
>;

export const UpdateReportAddOnBody = ReportAddOnDTO.omit({
  expert_notes_added_by_user: true,
}).partial();

export type UpdateReportAddOnBody = z.infer<typeof UpdateReportAddOnBody>;

import { z } from 'zod';
import { TagDTO } from '../tag-dto';

export const CustomerTagsResponse = z.array(
  TagDTO.pick({
    _id: true,
    name: true,
  })
);

export type CustomerTagsResponse = z.infer<typeof CustomerTagsResponse>;

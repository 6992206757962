import { z } from 'zod';
import { Sentiment } from '../../utils';
import { DateSchema } from '../../utils/date-schema';

const AssociatedArticleDTO = z.object({
  title: z.string().nullish(),
  url: z.string().nullish(),
  sentiment: Sentiment,
  favorability: z.number(),
});

const StakeholderDTO = z.object({
  name: z.string(),
  title: z.string().nullish(),
  role: z.string().nullish(),
  associated_articles: z.array(AssociatedArticleDTO).nullish(),
});

const OppositionPointsDTO = z.object({
  reason: z.string().nullish(),
  summary: z.string().nullish(),
  name: z.string().nullish(),
  title: z.string().nullish(),
  role: z.string().nullish(),
});

export type StakeholderDTO = z.infer<typeof StakeholderDTO>;

export type OppositionPointsDTO = z.infer<typeof OppositionPointsDTO>;

export const PulseSentimentNewsDTO = z.object({
  _id: z.string().uuid().nullish(),
  title: z.string().nullish(),
  favorability: z.number(),
  sentiment: Sentiment,
  url: z.string().nullish(),
  article_summary: z.string().nullish(),
  thumbnail: z.string().nullish(),
  published: DateSchema.nullish(),
  county: z.string(),
  state: z.string(),
  fav_reason: z.string().nullish(),
  publication_name: z.string().nullish(),
  stakeholders: z.array(StakeholderDTO).nullish(),
  opposition_points: z.array(OppositionPointsDTO).nullish(),
});

export type PulseSentimentNewsDTO = z.infer<typeof PulseSentimentNewsDTO>;

export const PulseSentimentNewsCountyDTO = z.object({
  name: z.string(),
  id: z.string().uuid().nullish(),
  solar_sentiment_news_data: z.array(PulseSentimentNewsDTO),
  sentiment_score: z.number(),
  sentiment: Sentiment,
});

export const PulseSentimentNewsItemsDTO = z.array(
  z.object({
    state: z.string(),
    state_abbreviation: z.string(),
    geography__id: z.string(),
    counties: z.array(PulseSentimentNewsCountyDTO),
  })
);

export type PulseSentimentNewsItemsDTO = z.infer<
  typeof PulseSentimentNewsItemsDTO
>;

import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ResponseRows } from '../../../utils/response-rows';
import { ReportConcernLevel } from '../report.dto';

const ReportContext = z.object({
  expert_review: z.string().nullish(),
  federal_funds: z.string().nullish(),
  project_type: z.string().nullish(),
  project_subtype: z.string().nullish(),
  construction_type: z.string().nullish(),
  plan_to_impact_waters: z.string().nullish(),
});

export const ReportMineDTO = z
  .object({
    _id: z.string().uuid(),
    name: z.string(),
    type: z.string(),
    status: z.string(),
    status_review: z.string(),
    parent_report_id: z.string().uuid().nullish(),
    created_at: DateSchema,
    context: ReportContext,
    concern_levels: z
      .object({
        waters: ReportConcernLevel,
        species: ReportConcernLevel,
        state_species: ReportConcernLevel,
        protected_area: ReportConcernLevel,
        environmental_compliance: ReportConcernLevel,
      })
      .nullish(),
    project: z
      .object({
        _id: z.string().uuid(),
        name: z.string(),
        client_identifier: z.string().nullish(),
        geographies_states: z
          .array(
            z.object({
              state: z.string(),
              counties: z.array(z.string()),
              abbreviation: z.string(),
              geography__id: z.string().uuid(),
            })
          )
          .nullish(),
        customer: z
          .object({
            account_name: z.string(),
            terms_accepted: z.boolean(),
            _id: z.string().uuid(),
            name: z.string(),
            firstname: z.string(),
            lastname: z.string(),
            terms_accepted_date: DateSchema.nullish(),
          })
          .nullish(),
        users: z.array(
          z.object({
            _id: z.string().uuid(),
            firstname: z.string(),
            lastname: z.string(),
          })
        ),
      })
      .nullish(),
    order: z
      .object({
        _id: z.string().uuid(),
        billing: z.object({
          email: z.string().nullish(),
          phone: z.string().nullish(),
          method: z.string().nullish(),
          lastname: z.string().nullish(),
          firstname: z.string().nullish(),
        }),
        total: z.number().nullish(),
        created_at: DateSchema,
        updated_at: DateSchema,
      })
      .nullish(),
    review_requested_at: DateSchema,
  })
  .deepPartial();

export type ReportMineDTO = z.infer<typeof ReportMineDTO>;

export const ReportMineResponseDTO = ResponseRows(ReportMineDTO);
export type ReportMineResponseDTO = z.infer<typeof ReportMineResponseDTO>;

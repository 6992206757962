import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  of,
  retry,
  switchMap,
  throwError,
  timer,
} from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckForUpdateService {
  unrecoverableState$: Observable<string>;

  private isNewVersionAvailable = new BehaviorSubject(false);
  isNewVersionAvailable$ = this.isNewVersionAvailable.asObservable();

  private isNewVersionReady = new BehaviorSubject(false);
  isNewVersionReady$ = this.isNewVersionReady.asObservable();

  listenToVersionEvents$: Observable<void>;

  constructor(private updates: SwUpdate, private ngZone: NgZone) {
    this.unrecoverableState$ = updates.unrecoverable.pipe(
      map((event) => event.reason)
    );

    this.listenToVersionEvents$ = updates.versionUpdates.pipe(
      map((evt) => {
        if (evt.type === 'VERSION_READY') {
          // eslint-disable-next-line no-console
          console.log(
            'New version is ready',
            evt.currentVersion,
            evt.latestVersion
          );
          this.isNewVersionReady.next(true);
          this.isNewVersionAvailable.next(true);
        }

        if (evt.type === 'VERSION_DETECTED') {
          // eslint-disable-next-line no-console
          console.log('New version detected', evt.version);
          this.isNewVersionAvailable.next(true);
        }

        return;
      })
    );
  }

  get isNewVersionAvailableValue() {
    return this.isNewVersionAvailable.getValue();
  }

  pollUpdateAvailability(period = 60 * 60 * 1000) {
    return this.ngZone.runOutsideAngular(() => {
      return of(this.updates.isEnabled).pipe(
        tap((isEnabled) => {
          if (!isEnabled) {
            throw new Error('Service worker not enabled.');
          }
        }),
        switchMap(() => from(this.updates.checkForUpdate())),
        switchMap((result) => {
          if (!result) {
            return throwError(() => new Error('No update available.'));
          }

          return of(result);
        }),
        catchError((error) => {
          return throwError(() => error as unknown);
        }),
        retry({
          resetOnSuccess: true,
          delay: (error, retryCount) => {
            if (
              !(
                error instanceof Error &&
                (error.message === 'No update available.' ||
                  error.message === 'Service worker not enabled.')
              )
            ) {
              // If unexpected error occurs throw and send to rollbar
              throw error;
            }

            // eslint-disable-next-line no-restricted-syntax
            console.debug(
              `Retry check for update #${retryCount} after failure`
            );
            return timer(period);
          },
        })
      );
    });
  }
}

import { Injectable, InjectionToken, Injector, Provider } from '@angular/core';

import {
  FormDTO,
  FormTypeDTO,
  FormTypeKey,
} from '@transect-nx/data-transfer-objects';
import { combineLatest, map, Observable } from 'rxjs';
import { IFormModalService } from '../models/form-modal.service';
import { FormAltaScopeOfWorkService } from '../modules/form-alta-scope-of-work/form-alta-scope-of-work.service';
import { FormMarketplaceAgreementService } from '../modules/form-marketplace-agreement/form-marketplace-agreement.service';
import { FormMarketPlacePhase1EsaAmendmentModalService } from '../modules/form-marketplace-phase1esa-amendment/form-marketplace-phase1esa-amendment.service';
import { FormMarketplaceSiteAccessAuthorizationModalService } from '../modules/form-marketplace-site-access-authorization/services/form-marketplace-site-access-authorization-modal.service';
import { FormMarketplaceUserQuestionnaireModalService } from '../modules/form-marketplace-user-questionnaire/form-marketplace-user-questionnaire.service';
import { FormThreatenedAndEndangeredSpeciesSurveyScopeOfWorkModalService } from '../modules/form-threatened-and-endangered-species-survey-scope-of-work/services/form-threatened-and-endangered-species-survey-scope-of-work.service';
import { FormUserFreeTrialAgreementService } from '../modules/form-user-free-trial-agreement/form-user-free-trial-agreement.service';
import { TransectFormStateService } from './transect-form-state.service';

export type FormConfigValue = {
  stateService: TransectFormStateService;
  modalService: IFormModalService;
  title: string;
  formType: FormTypeDTO;
  form?: FormDTO;
};

export type FormConfig = Record<FormTypeKey, FormConfigValue>;

@Injectable({
  providedIn: 'root',
})
export class TransectFormStateFactoryService {
  buildFormConfig(
    injector: Injector,
    forms: FormDTO[],
    requiredFormTypes: FormTypeDTO[]
  ): FormConfig {
    const config = requiredFormTypes.reduce<FormConfig>((acc, formType) => {
      const stateService = injector.get<TransectFormStateService>(
        FormStateTokenMap[formType.key]
      );
      const modalService = this.getModalService(injector, formType.key);
      const form = forms.find((form) => form.form_type__id === formType._id);
      const title = this.getTitle(formType.key);
      acc[formType.key] = {
        stateService,
        modalService,
        formType,
        form,
        title,
      };
      return acc;
    }, {} as FormConfig);
    return config;
  }

  buildFormConfigValue(
    injector: Injector,
    data: {
      form: {
        _id: string;
        form_type__id: string;
        creator__id: string;
        completed_by__id?: string;
        contents?: Record<string, string | number | boolean | Date>;
        completed_on?: Date;
      };
      formType: {
        _id: string;
        key: FormTypeKey;
        name: string;
      };
    }
  ) {
    const stateService = injector.get<TransectFormStateService>(
      FormStateTokenMap[data.formType.key]
    );
    const modalService = this.getModalService(injector, data.formType.key);
    const title = this.getTitle(data.formType.key);
    return {
      stateService,
      modalService,
      title,
      formType: data.formType,
      form: data.form,
    } as FormConfigValue;
  }

  areAllFormsComplete(formConfig: FormConfig): Observable<boolean> {
    const allIsComplete$ = Object.values(formConfig).map(
      (config) => config.stateService.isComplete$
    );
    return combineLatest(allIsComplete$).pipe(
      map((values) => {
        return values.every(Boolean);
      })
    );
  }

  private getTitle(formTypeKey: FormTypeKey): string {
    switch (formTypeKey) {
      case FormTypeKey.SITE_ACCESS_AUTHORIZATION:
        return 'Site Access Authorization Form';
      case FormTypeKey.USER_QUESTIONNAIRE:
        return 'User Questionnaire';
      case FormTypeKey.MARKETPLACE_AGREEMENT:
        return 'Marketplace Agreement';
      case FormTypeKey.PHASE1_ESA_AMENDMENT:
        return 'Phase 1 Amendment';
      case FormTypeKey.TESS_SCOPE_OF_WORK:
        return 'Threatened and Endangered Species Survey Scope of Work';
      case FormTypeKey.ALTA_SCOPE_OF_WORK:
        return 'ALTA Scope of Work';
      case FormTypeKey.FREE_TRIAL_AGREEMENT:
        return 'Free Trial Agreement';
      case FormTypeKey.LANDOWNER_CONTACT_TOC:
        return 'Terms and Conditions';
      default:
        throw new Error('unregistered form type');
    }
  }

  private getModalService(
    injector: Injector,
    formTypeKey: FormTypeKey
  ): IFormModalService {
    switch (formTypeKey) {
      case FormTypeKey.SITE_ACCESS_AUTHORIZATION:
        return injector.get(FormMarketplaceSiteAccessAuthorizationModalService);
      case FormTypeKey.USER_QUESTIONNAIRE:
        return injector.get(FormMarketplaceUserQuestionnaireModalService);
      case FormTypeKey.MARKETPLACE_AGREEMENT:
        return injector.get(FormMarketplaceAgreementService);
      case FormTypeKey.PHASE1_ESA_AMENDMENT:
        return injector.get(FormMarketPlacePhase1EsaAmendmentModalService);
      case FormTypeKey.TESS_SCOPE_OF_WORK:
        return injector.get(
          FormThreatenedAndEndangeredSpeciesSurveyScopeOfWorkModalService
        );
      case FormTypeKey.ALTA_SCOPE_OF_WORK:
        return injector.get(FormAltaScopeOfWorkService);
      case FormTypeKey.FREE_TRIAL_AGREEMENT:
        return injector.get(FormUserFreeTrialAgreementService);
      default:
        throw new Error('unregistered form type');
    }
  }
}

export const FormStateTokenMap: {
  [key in FormTypeKey]: InjectionToken<TransectFormStateService>;
} = {
  [FormTypeKey.MARKETPLACE_AGREEMENT]:
    new InjectionToken<TransectFormStateService>(
      FormTypeKey.MARKETPLACE_AGREEMENT
    ),
  [FormTypeKey.USER_QUESTIONNAIRE]: new InjectionToken(
    FormTypeKey.USER_QUESTIONNAIRE
  ),
  [FormTypeKey.SITE_ACCESS_AUTHORIZATION]: new InjectionToken(
    FormTypeKey.SITE_ACCESS_AUTHORIZATION
  ),
  [FormTypeKey.ALTA_SCOPE_OF_WORK]: new InjectionToken(
    FormTypeKey.ALTA_SCOPE_OF_WORK
  ),
  [FormTypeKey.PHASE1_ESA_AMENDMENT]: new InjectionToken(
    FormTypeKey.PHASE1_ESA_AMENDMENT
  ),
  [FormTypeKey.TESS_SCOPE_OF_WORK]: new InjectionToken(
    FormTypeKey.TESS_SCOPE_OF_WORK
  ),
  [FormTypeKey.FREE_TRIAL_AGREEMENT]: new InjectionToken(
    FormTypeKey.FREE_TRIAL_AGREEMENT
  ),
  [FormTypeKey.LANDOWNER_CONTACT_TOC]: new InjectionToken(
    FormTypeKey.LANDOWNER_CONTACT_TOC
  ),
  [FormTypeKey.CEII_NDA]: new InjectionToken(FormTypeKey.CEII_NDA),
};

export const FormStateSiteAccessAuthProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.SITE_ACCESS_AUTHORIZATION],
  useClass: TransectFormStateService,
};

export const FormStateUserQuestionnaireProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.USER_QUESTIONNAIRE],
  useClass: TransectFormStateService,
};

export const FormStateMarketplaceAgreementProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.MARKETPLACE_AGREEMENT],
  useClass: TransectFormStateService,
};

export const FormStateAltaScopeOfWorkProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.ALTA_SCOPE_OF_WORK],
  useClass: TransectFormStateService,
};

export const FormStatePhase1AmendmentProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.PHASE1_ESA_AMENDMENT],
  useClass: TransectFormStateService,
};

export const FormTessScopeOfWorkProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.TESS_SCOPE_OF_WORK],
  useClass: TransectFormStateService,
};

export const FormUserFreeTrialProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.FREE_TRIAL_AGREEMENT],
  useClass: TransectFormStateService,
};

export const FormLandownerContactTOCProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.LANDOWNER_CONTACT_TOC],
  useClass: TransectFormStateService,
};

export const FormCeiiNdaProvider: Provider = {
  provide: FormStateTokenMap[FormTypeKey.CEII_NDA],
  useClass: TransectFormStateService,
};

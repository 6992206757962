import { z } from 'zod';

export const SpeciesReferencesDTO = z.array(
  z.object({
    species: z.object({
      _id: z.string().uuid(),
    }),
    reference: z.any(),
  })
);

export type SpeciesReferencesDTO = z.infer<typeof SpeciesReferencesDTO>;

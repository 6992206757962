import { z } from 'zod';
import { FormTypeDTO } from '../form-type';

export const ReportAddOnOfferingKey = z.enum([
  'pzr',
  'slope',
  'archaeology',
  'local_rrs',
  'phase1_esa',
  'tess',
  'wetland_dln',
  'alta',
  'interconnection_study',
]);

export type ReportAddOnOfferingKey = z.infer<typeof ReportAddOnOfferingKey>;

export const ReportAddOnOfferingType = z.enum(['purchase', 'quote']);

export type ReportAddOnOfferingType = z.infer<typeof ReportAddOnOfferingType>;

export const ReportAddOnOfferingDTO = z
  .object({
    _id: z.string().uuid(),
    name: z.string(),
    key: ReportAddOnOfferingKey,
    description: z.string().nullish(),
    type: ReportAddOnOfferingType,
    why_do_i_need_this: z.string().nullish(),
    image_url: z.string().nullish(),
    deliverable_preview_link: z.string().nullish(),
    deliverable_image_url: z.string().nullish(),
    delivery_description_html: z.string().nullish(),
    details_description_html: z.string().nullish(),
    icon_class: z.string(),
    icon_image_url: z.string(),
    report_required: z.boolean(),
    quote_sla_description: z.string().nullish(),
    required_form_types: z.array(FormTypeDTO).nullish(),
  })
  .passthrough();

export type ReportAddOnOfferingDTO = z.infer<typeof ReportAddOnOfferingDTO>;

export const ReportAddOnOfferingKeyToNameMap = {
  pzr: 'Planning and Zoning Report',
  slope: 'Slope & Aspect Calculation',
  archaeology: 'SHPO Records Search',
  local_rrs: 'Local Environmental Regulations',
  phase1_esa: 'Phase 1 ESA',
  tess: 'Threatened and Endangered Species Survey',
  wetland_dln: 'Wetlands Delineation',
  alta: 'Land Title Field Survey',
  interconnection_study: 'Initial Interconnection Feasibility Assessment',
} as const;

export enum ReportAddOnOfferingEnum {
  PZR = 'pzr',
  SLOPE = 'slope',
  ARCHAEOLOGY = 'archaeology',
  LOCAL_RRS = 'local_rrs',
  PHASE1_ESA = 'phase1esa',
  TESS = 'tess',
  WETLAND_DLN = 'wetland_dln',
  ALTA = 'alta',
  INTERCONNECTION = 'interconnection_study',
}

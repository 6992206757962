import { z } from 'zod';

// getCustomerForms BEGIN
export const GetCustomerFormsParams = z.object({
  customerId: z.string().uuid(),
});

export type GetCustomerFormsParams = z.infer<typeof GetCustomerFormsParams>;

export const GetCustomerFormsQueryParams = z.object({
  type: z.string().optional(),
  onlyCompleted: z
    .string()
    .default('false')
    .transform((value) => value === 'true'),
});

export type GetCustomerFormsQueryParams = z.infer<
  typeof GetCustomerFormsQueryParams
>;

export const GetCustomerFormsResponse = z.array(
  z.object({
    _id: z.string().uuid(),
    completed_by: z
      .object({
        _id: z.string().uuid(),
        firstname: z.string(),
        lastname: z.string(),
      })
      .nullable(),
  })
);

export type GetCustomerFormsResponse = z.infer<typeof GetCustomerFormsResponse>;

// getCustomerForms END

// uploadCustomerFile BEGIN
export const UploadCustomerFileParams = z.object({
  customerId: z.string().uuid(),
});

export type UploadCustomerFileParams = z.infer<typeof UploadCustomerFileParams>;

export const UploadCustomerFileBody = z.object({
  fileId: z.string().uuid(),
  type: z.enum(['marketplace_agreement']),
});

export type UploadCustomerFileBody = z.infer<typeof UploadCustomerFileBody>;

// uploadCustomerFile END

// getCustomerFiles BEGIN
export const GetCustomerFilesParams = z.object({
  customerId: z.string().uuid(),
});

export type GetCustomerFilesParams = z.infer<typeof GetCustomerFilesParams>;

export const GetCustomerFilesQueryParams = z.object({
  type: z.enum(['marketplace_agreement']).optional(),
});

export type GetCustomerFilesQueryParams = z.infer<
  typeof GetCustomerFilesQueryParams
>;

export const GetCustomerFilesResponse = z.array(
  z.object({
    _id: z.string().uuid(),
    name: z.string(),
  })
);

export type GetCustomerFilesResponse = z.infer<typeof GetCustomerFilesResponse>;
// getCustomerFile END

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';
import { FormModalInputData } from '../../models/form-modal.service';

@Component({
  selector: 'ts-form-marketplace-user-questionnaire',
  templateUrl: './form-marketplace-user-questionnaire.component.html',
  styleUrls: ['./form-marketplace-user-questionnaire.component.scss'],
})
export class FormMarketplaceUserQuestionnaireComponent
  implements OnInit, OnDestroy
{
  private readonly destroyAction = new Subject<void>();

  userQuestionnaireForm = new UntypedFormGroup({
    environmentalAwareness: new UntypedFormControl('', [Validators.required]),
    landTitleRecordsAwareness: new UntypedFormControl('', [
      Validators.required,
    ]),
    specializedKnowledgeOrExperience: new UntypedFormControl('', [
      Validators.required,
    ]),
    reasonablePurchasePrice: new UntypedFormControl('', [Validators.required]),
    propertyPastUses: new UntypedFormControl('', [Validators.required]),
    chemicalPresence: new UntypedFormControl('', [Validators.required]),
    chemicalSpills: new UntypedFormControl('', [Validators.required]),
    environmentalCleanups: new UntypedFormControl('', [Validators.required]),
  });

  canSave$ = this.formStateService.canSave$;

  completing$ = this.formStateService.completing$;
  isComplete$ = this.formStateService.isComplete$;
  isFetchingPdf$ = this.formStateService.isFetchingPdf$;
  inProgress$ = this.formStateService.inProgress$;

  saveButtonColor$ = this.formStateService.saveButtonColor$;

  get formStateService() {
    return this.data.stateService;
  }

  constructor(
    private dialogRef: MatDialogRef<FormMarketplaceUserQuestionnaireComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: FormModalInputData
  ) {}

  ngOnInit(): void {
    if (this.formStateService.stateValue.formValue) {
      this.userQuestionnaireForm.patchValue(
        this.formStateService.stateValue.formValue,
        { emitEvent: false }
      );
      this.formStateService.dispatchFormValidAction(
        !this.userQuestionnaireForm.invalid
      );
    }

    if (this.formStateService.stateValue.isComplete || this.data.readOnly) {
      this.userQuestionnaireForm.disable();
    }

    this.userQuestionnaireForm.valueChanges
      .pipe(
        filter(() => this.userQuestionnaireForm.dirty),
        switchMap((formValue: FormDTO['contents']) => {
          return this.formStateService.dispatchFormValueChangeAction(
            this.userQuestionnaireForm.valid,
            formValue,
            this.data.formTypeId,
            this.data.addOnId
          );
        }),
        takeUntil(this.destroyAction)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyAction.next();
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  handleSaveClick() {
    if (!this.userQuestionnaireForm.valid) {
      return;
    }
    this.formStateService.dispatchCompleteFormAction().subscribe(() => {
      this.dialogRef.close();
    });
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }
}

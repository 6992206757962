import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { UserDTO } from '../users';
import { TagDTO } from '../tag/tag-dto';

export const TaggingDTO = z.object({
  _id: z.string().uuid(),
  taggable__id: z.string().uuid(),
  created_at: DateSchema,
  creator: z.preprocess(
    (val) => (typeof val === 'string' ? { _id: val } : val),
    UserDTO.partial().nullish()
  ),
  tag: z.preprocess(
    (val) => (typeof val === 'string' ? { _id: val } : val),
    TagDTO.partial().nullish()
  ),
  taggable__type: z.string(),
});

export type TaggingDTO = z.infer<typeof TaggingDTO>;

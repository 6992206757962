import { z } from 'zod';
import { safeNumber } from '../../utils/string-to-number';

export enum Operator {
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  LIKE = 'like',
  I_LIKE = 'iLike',
  IN = 'in',
  NOT_IN = 'notIn',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
}

export enum CoverageCountOperator {
  EQUAL = 'eq',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
}

export const TriggerConditionDTO = z.object({
  order: z.number(),
  source: z.string().optional(),
  data_type: z.union([
    z.literal('project_context'),
    z.literal('transect_data'),
    z.literal('customer'),
    z.literal('report_content'),
  ]),
  query_type: z.string().optional(),
  coverage_type: z
    .union([z.literal('within_distance'), z.literal('aoi')])
    .optional(),
  coverage_count: z
    .union([z.literal('presence'), z.literal('absence'), z.literal('formula')])
    .optional(),
  coverage_count_operator: z.nativeEnum(CoverageCountOperator).optional(),
  coverage_count_value: safeNumber.optional(),
  query_attributes: z
    .array(
      z.object({
        key: z.string(),
        value: z.union([z.string(), safeNumber, z.boolean()]),
        operator: z.nativeEnum(Operator),
      })
    )
    .optional(),
  attribute_name: z.string().optional(),
  attribute_value: z.string().optional(),
  distance: safeNumber.optional(),
});

export type TriggerConditionDTO = z.infer<typeof TriggerConditionDTO>;

export const TriggerDTO = z.object({
  name: z.string(),
  conditions: z.array(TriggerConditionDTO).optional(),
  recommendation: z.string().optional(),
});

export type TriggerDTO = z.infer<typeof TriggerDTO>;

export const TriggerGroupDTO = z.object({
  name: z.string(),
  order: z.number(),
  triggers: z.array(TriggerDTO).optional(),
});

export type TriggerGroupDTO = z.infer<typeof TriggerGroupDTO>;

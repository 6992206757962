import { z } from 'zod';
import { stringToNumber } from '../../../utils/string-to-number';

export enum ParcelConcernLevelType {
  SPECIES = 'species',
  WATER = 'water',
  PROTECTED_AREA = 'protected_area',
  ENVIRONMENTAL_COMPLIANCE = 'environmental_compliance',
}

export const ParcelConcernLevelBreakdownDTO = z.array(
  z.object({
    percentage: z.preprocess(
      (val) => stringToNumber(val as string),
      z.number().min(0).max(100)
    ),
    type: z.nativeEnum(ParcelConcernLevelType),
    value: z.number().min(0).max(3),
  })
);

export type ParcelConcernLevelBreakdownDTO = z.infer<
  typeof ParcelConcernLevelBreakdownDTO
>;

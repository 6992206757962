import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  AfterViewInit,
  AfterViewChecked,
  Renderer2,
} from '@angular/core';

type Position = {
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
};

@Directive({
  selector: '[tsDraggableWindow]',
})
export class DraggableWindowDirective
  implements AfterViewInit, AfterViewChecked
{
  @Input() draggingEnabled = true;

  /**
   * The window's starting coordinates in px
   */
  @Input() position?: Position;

  private baseZIndex = 3;

  private draggableContainer?: HTMLDivElement;
  private moving = false;
  private pos = {
    x: 0,
    y: 0,
  };

  private cursorStyle?: string;

  constructor(
    private elRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {
    if (!this.draggingEnabled) {
      return;
    }

    if (this.elRef?.nativeElement?.style?.zIndex) {
      this.elRef.nativeElement.style.zIndex = this.baseZIndex.toString();
    }

    this.renderer.addClass(this.elRef.nativeElement, 'draggable-element');
  }

  ngAfterViewChecked(): void {
    if (!this.draggingEnabled) {
      return;
    }

    this.constrainWithinBounds();
  }

  ngAfterViewInit(): void {
    if (!this.draggingEnabled) {
      return;
    }

    if (!this.draggableContainer) {
      let parent: HTMLElement | null = this.elRef.nativeElement.parentElement;

      // eslint-disable-next-line no-constant-condition
      while (parent) {
        const div = parent.querySelector<HTMLDivElement>(
          ':scope > div.dragging-container'
        );

        if (div) {
          this.draggableContainer = div;
          break;
        }

        parent = parent.parentElement;
      }
    }

    if (!this.draggableContainer) {
      throw new Error(
        'Could not find a parent container to support the draggable window.'
      );
    }

    const target = this.elRef.nativeElement as HTMLDivElement;
    const pRect = this?.draggableContainer?.getBoundingClientRect();
    const tgtRect = target.getBoundingClientRect();

    if (!this.position) {
      return;
    }

    for (const [key, val] of Object.entries(this.position)) {
      const stringValue = typeof val === 'number' ? `${val}px` : val;

      target.style[key as keyof Position] = stringValue;
    }
  }

  @HostListener('mousedown', ['$event'])
  startMove(event: MouseEvent): void {
    if (!this.draggingEnabled) {
      return;
    }

    this.bringToForeground();

    const target = this.elRef.nativeElement as HTMLDivElement | null;

    let eventTarget = event.target as HTMLElement | null;
    const draggableHandle =
      target?.querySelector<HTMLElement>('.draggable-handle');

    while (
      draggableHandle &&
      !eventTarget?.classList.contains('draggable-handle')
    ) {
      eventTarget = eventTarget?.parentElement ?? null;

      if (!eventTarget) {
        return;
      }
    }

    this.moving = true;

    if (target instanceof Element) {
      const computedStyle = window.getComputedStyle(target);
      this.pos.x = event.clientX - Number(computedStyle.left.slice(0, -2));
      this.pos.y = event.clientY - Number(computedStyle.top.slice(0, -2));
    }
  }

  @HostListener('document:mousemove', ['$event'])
  move(event: MouseEvent): void {
    if (!this.draggingEnabled) {
      return;
    }

    if (this.moving) {
      this.pauseEvent(event);

      const target = this.elRef.nativeElement;
      const draggableHandle =
        target?.querySelector<HTMLElement>('.draggable-handle');

      if (draggableHandle) {
        draggableHandle.style.cursor = 'grabbing';
      } else {
        this.cursorStyle = this.elRef.nativeElement.style.cursor;
        this.elRef.nativeElement.style.cursor = 'grabbing';
      }

      if (target) {
        target.style.left = `${event.clientX - this.pos.x}px`;
        target.style.top = `${event.clientY - this.pos.y}px`;
      }

      this.constrainWithinBounds();
    }
  }

  @HostListener('document:mouseup')
  stopMove(): void {
    if (!this.draggingEnabled) {
      return;
    }

    this.moving = false;

    const target = this.elRef.nativeElement;
    const draggableHandle =
      target?.querySelector<HTMLElement>('.draggable-handle');

    if (draggableHandle) {
      draggableHandle.style.cursor = 'grab';
    } else {
      this.elRef.nativeElement.style.cursor = this.cursorStyle ?? 'default';
    }
  }

  pauseEvent(e: MouseEvent): boolean {
    if (!this.draggingEnabled) {
      return false;
    }

    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  }

  private bringToForeground(): void {
    if (!this.draggingEnabled) {
      return;
    }

    const target = this.elRef.nativeElement as HTMLDivElement | null;
    const draggableElements =
      this.draggableContainer?.querySelectorAll('.draggable-element');

    const maxZIndex = this.baseZIndex + (draggableElements?.length ?? 0);

    draggableElements?.forEach((element) => {
      const zIndex = Number(window.getComputedStyle(element).zIndex);

      if (zIndex > this.baseZIndex) {
        this.renderer.setStyle(element, 'z-index', zIndex - 1);
      }
    });

    if (target?.style) {
      target.style.zIndex = maxZIndex.toString();
    }
  }

  private constrainWithinBounds(): void {
    if (!this.draggingEnabled) {
      return;
    }

    const target = this.elRef.nativeElement;

    const pRect = this?.draggableContainer?.getBoundingClientRect();
    const tgtRect = target.getBoundingClientRect();

    if (!pRect) {
      return;
    }

    if (tgtRect.left < pRect.left) {
      target.style.left = '0px';
    }
    if (tgtRect.top < pRect.top) {
      target.style.top = '0px';
    }
    if (tgtRect.right > pRect.right) {
      target.style.left = `${pRect.width - tgtRect.width}px`;
    }
    if (tgtRect.bottom > pRect.bottom) {
      target.style.top = `${pRect.height - tgtRect.height}px`;
    }
  }
}

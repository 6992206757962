import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { CustomerDTO } from '../../customers';
import { UserDTO } from '../../users';
import { ProjectDTO } from '../project.dto';

export const ProjectPaginatedResponseRows = PaginatedResponseRows(
  ProjectDTO.pick({
    _id: true,
    name: true,
    client_identifier: true,
    creator__id: true,
    customer__id: true,
    geographies_states: true,
    created_at: true,
    updated_at: true,
  }).extend({
    creator: UserDTO.pick({
      _id: true,
      firstname: true,
      lastname: true,
      fullname: true,
    }).nullable(),
    customer: CustomerDTO.pick({
      _id: true,
      name: true,
      account_name: true,
      terms_accepted: true,
    }).nullable(),
    service_area: z.string().nullable(),
    in_service_area: z.boolean().default(false),
  })
);

export type ProjectPaginatedResponseRows = z.infer<
  typeof ProjectPaginatedResponseRows
>;

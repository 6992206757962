import { z } from 'zod';
import { StringSetFilter, TextFilter } from '../../../utils/aggrid-filters';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';
import { jsonSchema } from '../../../utils/string-to-json';

export const RegulationControllerPaginatedTreeBody = z.object({
  search: z.string().nullish(),
  groupKeys: jsonSchema(z.array(z.string().uuid())),
  filterModel: jsonSchema(
    z.object({
      name: TextFilter.nullish(),
      'customer.name': TextFilter.nullish(),
      'regulator.name': TextFilter.nullish(),
      'geography._id': StringSetFilter.nullish(),
      jurisdiction: StringSetFilter.nullish(),
      topic: StringSetFilter.nullish(),
      status_expert: StringSetFilter.nullish(),
      active: StringSetFilter.nullish(),
      region: StringSetFilter.nullish(),
      owner__id: StringSetFilter.nullish(),
    })
  ).optional(),
  sortModel: sortModel.optional(),
  page: pageNumber,
  pageSize: pageSize.default('100'),
});

export type RegulationControllerPaginatedTreeBody = z.infer<
  typeof RegulationControllerPaginatedTreeBody
>;

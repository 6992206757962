import { Feature, LineString, Point } from '@turf/turf';

export type TransectAPIErrorCodes =
  | 'ERR_FREE_USER_PROJECT_CREATION_LIMIT'
  | 'PROJECT_MONITOR_LIMIT_REACHED';

export enum ElectricGeneratorDataCategory {
  PLANNED = 'planned',
  OPERATING = 'operating',
  CANCELED_POSTPONED = 'canceled_postponed',
}

export enum ElectricGeneratorDataTechnology {
  SOLAR = 'solar',
  WIND = 'wind',
}

export enum AssessmentLevels {
  IGNORE = 'ignore',
  SPECIES_OF_CONCERN = 'species_of_concern',
  MAY_OCCUR = 'may_occur',
  NOT_LIKELY_TO_OCCUR = 'not_likely_to_occur',
  LIKELY_TO_OCCUR = 'likely_to_occur',
  NONE = 'none',
}

export enum PulseTechnology {
  SOLAR = 'solar',
  WIND = 'wind',
}

export enum PulseNewsTagsName {
  SOLAR = 'solar',
  WIND = 'wind',
}

export enum PulseRegulationsTechnology {
  SOLAR = 'solar',
  WIND = 'wind',
}

export const PulseTechnologyToPulseNewsTag: Record<
  PulseTechnology,
  PulseNewsTagsName
> = {
  [PulseTechnology.SOLAR]: PulseNewsTagsName.SOLAR,
  [PulseTechnology.WIND]: PulseNewsTagsName.WIND,
};

export const PulseTechnologyToElectricData: Record<
  PulseTechnology,
  ElectricGeneratorDataTechnology
> = {
  [PulseTechnology.SOLAR]: ElectricGeneratorDataTechnology.SOLAR,
  [PulseTechnology.WIND]: ElectricGeneratorDataTechnology.WIND,
};

export const PulseTechnologyToPulseRegulationsTechnology: Record<
  PulseTechnology,
  PulseRegulationsTechnology
> = {
  [PulseTechnology.SOLAR]: PulseRegulationsTechnology.SOLAR,
  [PulseTechnology.WIND]: PulseRegulationsTechnology.WIND,
};

export type LinearData =
  | {
      trigger_type: 'spatial';
      intersections:
        | (
            | {
                intersection_type: 'segment';
                data: {
                  start: { milestone: number };
                  end: { milestone: number };
                  line: LineString;
                };
              }
            | {
                intersection_type: 'point';
                data: { point: Point; milestone: number };
              }
          )[];
    }
  | {
      trigger_type: 'non_spatial';
      intersections: undefined;
    };

export type LinearDataParams = {
  aoi_intersection_start_point: string | null;
  aoi_intersection_end_point: string | null;
  aoi_intersection_start_point_distance_meters: number;
  aoi_intersection_end_point_distance_meters: number;
  geometry: string;
};

export type TransectSummary =
  | TransectSummaryAOI
  | TransectSummaryBuffer
  | TransectSummaryExternal
  | TransectSummaryUndefinedLocation
  | TransectSummaryAnonymized;

export interface TransectSummaryAOI {
  location: 'aoi';
  area: {
    meters: number;
    feet: number;
    miles: number;
    acres: number;
  };
  length: {
    miles: number;
    feet: number;
  };
  center: Feature<Point> | null;
  linear_data?: LinearData;
}

export interface TransectSummaryBuffer {
  location: 'buffer';
  area: {
    meters: number;
    feet: number;
    miles: number;
    acres: number;
  };
  length: {
    miles: number;
    feet: number;
  };
  center: Feature<Point> | null;
}

export interface TransectSummaryUndefinedLocation {
  location: undefined;
  linear_data?: LinearData;
}

export interface TransectSummaryExternal {
  location: 'external';
  isExternal: boolean;
  bearing: number;
  distance: {
    miles: number;
    feet: number;
  };
}

export interface TransectSummaryAnonymized {
  location: 'anonymized';
  isExternal: boolean;
  center: Feature<Point> | null;
}

export enum CriticalFailureTypes {
  REPORT_GENERATION = 'Report Generation',
  REPORT_EXPORT = 'Report Export',
  BUILDABLE_AREA_CREATION = 'Buildable Area Creation',
  PDF_GENERATION = 'PDF Generation',
  KML_GENERATION = 'KML Generation',
  REPORT_PART = 'Report Part',
}

export enum AtlassianServiceDesk {
  DataRequests = '1',
}

export enum AtlassianRequestType {
  CustomerDataRequest = '4',
}

export enum AtlassianField {
  Customer = 'customfield_10031',
  Styling = 'customfield_10066',
}

export enum HubspotEmailId {
  WATER_FIDGET_EXPORT_COMPLETE = 69279819608,
}

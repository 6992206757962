import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export enum ReportSectionKeys {
  EXECUTIVE_SUMMARY = 'executive_summary',
  PERMIT_MATRIX = 'permit_matrix',
  SPECIES_FEDERAL_PROFILES = 'species_federal_profiles',
  SPECIES_STATE_PROFILES = 'species_state_profiles',
  WATERS = 'waters',
  PROTECTED_AREAS = 'protected_areas',
  ENVIRONMENTAL_COMPLIANCE = 'environmental_compliance',
  SETTING_SOILS = 'setting_soils',
  SETTING_PARCELS = 'setting_parcels',
}

export const ReportSectionNoteDTO = z.object({
  _id: z.string().uuid(),
  report__id: z.string().uuid(),
  creator__id: z.string().uuid(),
  report_section_key: z.nativeEnum(ReportSectionKeys),
  note_content: z.string().min(1),
  last_edited_on: z.date(),
  updated_at: DateSchema,
});

export const CreateReportSectionNoteRequestDTO = z.object({
  report__id: z.string().uuid(),
  report_section_key: z.nativeEnum(ReportSectionKeys),
  note_content: z.string().min(1),
});

export const UpdateReportSectionNoteRequestDTO = z.object({
  report__id: z.string().uuid(),
  note_content: z.string().min(1),
  last_updated_at: DateSchema,
});

export const GetReportSectionNoteResponseDTO = z
  .object({
    _id: z.string().uuid(),
    note_content: z.string().min(1),
    last_edited_on: z.date(),
    report__id: z.string().uuid(),
  })
  .nullable();

export const GetReportAllSectionNotesResponseDTO = z.array(
  z.object({
    _id: z.string().uuid(),
    report__id: z.string().uuid(),
    note_content: z.string().min(1),
    last_edited_on: z.date(),
    report_section_key: z.nativeEnum(ReportSectionKeys),
    updated_at: DateSchema,
  })
);

export type ReportSectionNoteDTO = z.infer<typeof ReportSectionNoteDTO>;

export type CreateReportSectionNoteRequestDTO = z.infer<
  typeof CreateReportSectionNoteRequestDTO
>;

export type UpdateReportSectionNoteRequestDTO = z.infer<
  typeof UpdateReportSectionNoteRequestDTO
>;

export type GetReportSectionNoteResponseDTO = z.infer<
  typeof GetReportSectionNoteResponseDTO
>;

export type GetReportAllSectionNotesResponseDTO = z.infer<
  typeof GetReportAllSectionNotesResponseDTO
>;

import { z } from 'zod';
export const stringToJSON = <T>(val: string): T | undefined | string => {
  try {
    if (val) {
      return JSON.parse(val) as T;
    }
    return undefined;
  } catch (error) {
    return val;
  }
};

export function jsonSchema<T extends z.ZodArray<z.AnyZodObject | z.ZodString>>(
  zodArray: T
): z.ZodEffects<T, T['_output'], T['_input']>;

export function jsonSchema<T extends z.AnyZodObject | z.ZodString>(
  zodObject: T
): z.ZodEffects<T, T['_output'], T['_input']>;

export function jsonSchema<T extends z.AnyZodObject>(zodObject: T) {
  return z.preprocess((val) => stringToJSON(val as string), zodObject);
}

import { OverlayRef } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MatRipple, RippleConfig } from '@angular/material/core';
import { MenuContainerStrategy } from '../../../menu/menu-container-strategy';

@Component({
  selector: '[transect-nx-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [MatRipple, MenuContainerStrategy],
})
export class ButtonComponent implements OnInit, OnChanges {
  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  @HostBinding('class.size-xxs')
  get isDoubleExtraSmall(): boolean {
    return this.size === 'xxs';
  }

  @HostBinding('class.size-xs')
  get isExtraSmall(): boolean {
    return this.size === 'xs';
  }

  @HostBinding('class.size-s')
  get isSmall(): boolean {
    return this.size === 'sm';
  }

  @HostBinding('class.size-m')
  get isMedium(): boolean {
    return this.size === 'md';
  }

  @HostBinding('class.size-l')
  get isLarge(): boolean {
    return this.size === 'lg';
  }

  @HostBinding('class.icon-only')
  get isIconOnly(): boolean {
    return this.iconOnly;
  }

  @HostBinding('class.ts-btn-flat')
  get isFlat(): boolean {
    return this.buttonStyle === 'flat';
  }

  @HostBinding('class.ts-btn-outlined')
  get isOutlined(): boolean {
    return this.buttonStyle === 'outlined';
  }

  @HostBinding('class.ts-btn-plain')
  get isPlain(): boolean {
    return this.buttonStyle === 'plain';
  }

  @HostBinding('class.ts-btn-contained')
  get isContained(): boolean {
    return this.buttonStyle === 'contained';
  }

  @HostBinding('class.secondary-btn')
  get isSecondary(): boolean {
    return this.color === 'secondary';
  }

  @HostBinding('class.muted-btn')
  get isMute(): boolean {
    return this.color === 'muted';
  }

  @HostBinding('class.white-btn')
  get isWhite(): boolean {
    return this.color === 'white';
  }

  @HostBinding('class.ts-btn-negative')
  get isNegative(): boolean {
    return this.negative;
  }

  @HostBinding('class.ts-btn-align-left')
  get isLeftAligned(): boolean {
    return this.align === 'left';
  }

  @HostBinding('class.ts-btn-align-right')
  get isRightAligned(): boolean {
    return this.align === 'right';
  }

  @HostBinding('class.ts-btn-block')
  get isBlock(): boolean {
    return this.block;
  }

  constructor(
    private ripple: MatRipple,
    private elementRef: ElementRef<HTMLButtonElement>,
    private renderer: Renderer2,
    private menuContainerStrategy: MenuContainerStrategy,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input() block = false;
  @Input() align: 'left' | 'center' | 'right' = 'center';
  @Input() buttonStyle: 'flat' | 'outlined' | 'contained' | 'plain' = 'flat';
  @Input() negative = false;
  @Input() iconOnly = false;
  @Input() size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' = 'md';
  @Input() color: 'secondary' | 'primary' | 'muted' | 'white' = 'primary';
  @Input() disabled = false;
  @Input() customSize = 0;
  @Input() menuTemplate?: TemplateRef<ElementRef>;

  overlayRef?: OverlayRef | null;
  @HostListener('mousedown', ['$event']) onmousedown(event: MouseEvent): void {
    const config: RippleConfig = {
      color: 'rgba(189, 210, 212, 0.3)',
    };

    if (this.negative) {
      config.color = 'rgba(221, 34, 34, 0.2)';
    }

    this.ripple.launch(event.x, event.y, config);

    if (this.disabled) {
      event.stopImmediatePropagation();
    }
  }

  @HostListener('click', ['$event'])
  handleClick() {
    if (this.menuTemplate) {
      this.menuContainerStrategy.toggleMenu(
        this.menuTemplate,
        this.viewContainerRef,
        this.elementRef
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.elementRef.nativeElement.disabled = this.disabled;
    }
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.disabled = this.disabled;

    this.customSize = Math.max(this.customSize, 0);

    if (this.customSize > 0 && this.iconOnly) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'width',
        `${this.customSize}px`
      );
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'height',
        `${this.customSize}px`
      );

      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'font-size',
        `${Math.floor(this.customSize * 0.6)}px`
      );
    } else if (this.customSize > 0 && !this.iconOnly) {
      throw new Error(
        'the [customSize] attribute can only be used when [iconOnly] is true'
      );
    }
  }
}

import { Injectable } from '@angular/core';
import {
  InProgressRequestDTO,
  PusherEvents,
} from '@transect-nx/data-transfer-objects';
import _isEqual from 'lodash/isEqual';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { InProgressRequestsApiService } from '../../../../../services/backend-api/in-progress-requests.service';
import { PusherService } from '../../../../../services/pusher.service';

@Injectable({
  providedIn: 'root',
})
export class InProgressRequestsMenuService {
  private state = new BehaviorSubject<InProgressRequestDTO[]>([]);
  public inProgressRequests$ = this.state
    .asObservable()
    .pipe(distinctUntilChanged((prev, curr) => _isEqual(prev, curr)));

  private completeOrTimeoutState = new BehaviorSubject<InProgressRequestDTO[]>(
    []
  );
  public completeOrTimeoutRequests$ = this.completeOrTimeoutState
    .asObservable()
    .pipe(distinctUntilChanged((prev, curr) => _isEqual(prev, curr)));

  private readonly inProgressStatuses = [
    'pending',
    'in-progress',
    'in_progress',
    'requested',
  ];
  private readonly otherStatuses = ['complete', 'timeout'];

  constructor(
    private pusherService: PusherService,
    private inProgressRequestsApiService: InProgressRequestsApiService
  ) {}

  initInProgressRequestsListener() {
    return this.pusherService.listenToEvents$(PusherEvents.IN_PROGRESS_REQUEST);
  }

  fetchAndSetInProgressRequests() {
    return this.inProgressRequestsApiService.getAllInProgressRequests().pipe(
      tap((requests) => {
        this.state.next(
          requests.filter((request) =>
            this.inProgressStatuses.includes(request.status)
          )
        );
        this.completeOrTimeoutState.next(
          requests.filter((request) =>
            this.otherStatuses.includes(request.status)
          )
        );
      })
    );
  }

  handleInProgressRequestReceived(inProgressRequest: InProgressRequestDTO) {
    const existingInProgressRequests = this.state.getValue();
    const existingCompleteOrTimeoutStatusesRequests =
      this.completeOrTimeoutState.getValue();

    const updateRequestList = (
      requests: InProgressRequestDTO[],
      request: InProgressRequestDTO,
      shouldAdd: boolean
    ) => {
      const index = requests.findIndex((r) => r._id === request._id);
      if (shouldAdd) {
        return index > -1
          ? requests.map((r) => (r._id === request._id ? request : r))
          : requests.concat(request);
      } else {
        return requests.filter((r) => r._id !== request._id);
      }
    };

    const newInProgressRequests = updateRequestList(
      existingInProgressRequests,
      inProgressRequest,
      this.isRequestInProgress(inProgressRequest.status)
    );

    const completeOrTimeoutStatusRequests = updateRequestList(
      existingCompleteOrTimeoutStatusesRequests,
      inProgressRequest,
      this.isCompleteOrTimeoutStatus(inProgressRequest.status)
    );

    this.state.next(newInProgressRequests);
    this.completeOrTimeoutState.next(completeOrTimeoutStatusRequests);
  }

  private isRequestInProgress(status: InProgressRequestDTO['status']) {
    return this.inProgressStatuses.includes(status);
  }

  private isCompleteOrTimeoutStatus(status: InProgressRequestDTO['status']) {
    return this.otherStatuses.includes(status);
  }
}

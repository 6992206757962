import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import { debounceTime, filter, map, Subject, switchMap, takeUntil } from 'rxjs';
import { FormModalInputData } from '../../../../models/form-modal.service';
import { AltaScopeOfWorkFormComponent } from '../alta-scope-of-work-form/alta-scope-of-work-form.component';

@Component({
  selector: 'ts-form-alta-scope-of-work',
  templateUrl: './form-alta-scope-of-work.component.html',
  styleUrls: ['./form-alta-scope-of-work.component.scss'],
})
export class FormAltaScopeOfWorkComponent implements OnDestroy, AfterViewInit {
  @ViewChild(AltaScopeOfWorkFormComponent)
  childComponent: AltaScopeOfWorkFormComponent;

  get formStateService() {
    return this.data.stateService;
  }
  private destroy$ = new Subject<void>();
  selectedOptions: { [key: string]: boolean | string };

  isComplete$ = this.formStateService.isComplete$;
  isFetchingPdf$ = this.formStateService.isFetchingPdf$;
  canSave$ = this.formStateService.canSave$.pipe(debounceTime(100));
  inProgress$ = this.formStateService.inProgress$.pipe(debounceTime(100));
  saveButtonColor$ = this.formStateService.saveButtonColor$;

  constructor(
    private dialogRef: MatDialogRef<FormAltaScopeOfWorkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormModalInputData
  ) {}

  ngAfterViewInit() {
    this.childComponent.altaScopeOfWorkUIForm.valueChanges
      .pipe(
        debounceTime(100),
        filter(() => this.childComponent.altaScopeOfWorkUIForm.dirty),
        switchMap(() => {
          return this.formStateService.dispatchFormValueChangeAction(
            this.childComponent.altaScopeOfWorkUIForm.valid,
            this.childComponent.altaScopeOfWorkUIForm.getRawValue() as FormDTO['contents'],
            this.data.formTypeId,
            this.data.addOnId
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    if (this.formStateService.stateValue.isComplete || this.data.readOnly) {
      this.childComponent.altaScopeOfWorkUIForm.disable();
    }
    if (this.formStateService.stateValue.formValue) {
      this.childComponent.altaScopeOfWorkUIForm.patchValue(
        this.formStateService.stateValue.formValue
      );

      this.selectedOptions = this.childComponent.altaScopeOfWorkUIForm
        .value as { [key: string]: boolean | string };

      this.formStateService.dispatchFormValidAction(
        !this.childComponent.altaScopeOfWorkUIForm.invalid
      );
    }
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleSaveClick() {
    if (!this.childComponent.altaScopeOfWorkUIForm.valid) {
      return;
    }
    this.formStateService
      .dispatchCompleteFormAction()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }
}

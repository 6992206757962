import { z } from 'zod';

export enum ParcelCollectionStatus {
  REQUESTED = 'requested',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export const ParcelCollectionRequestDTO = z.object({
  combine_by_owner: z.preprocess(
    (val) => (typeof val === 'string' ? val === 'true' : val),
    z.boolean().default(false)
  ),
  object__id: z.string().uuid(),
  object__type: z.enum(['projects', 'parcel_filters']),
  status: z.nativeEnum(ParcelCollectionStatus).optional(),
});

export type ParcelCollectionRequestDTO = z.infer<
  typeof ParcelCollectionRequestDTO
>;

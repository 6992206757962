import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartGeologyFeatureDTO } from '../models/report-part-geology-feature.dto';

export const PaginatedReportPartGeologyFeaturesDTO = PaginatedResponseRows(
  ReportPartGeologyFeatureDTO
);

export type PaginatedReportPartGeologyFeaturesDTO = z.infer<
  typeof PaginatedReportPartGeologyFeaturesDTO
>;

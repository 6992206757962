import { z } from 'zod';
import { MarketplaceProductCategoryDTO } from '../marketplace-product-category.dto';

export const MarketplaceProductCategoriesResponseDTO = z.array(
  MarketplaceProductCategoryDTO.extend({
    children: z.array(
      MarketplaceProductCategoryDTO.extend({
        checked: z.boolean().default(false),
      })
    ),
  })
);
export type MarketplaceProductCategoriesResponseDTO = z.infer<
  typeof MarketplaceProductCategoriesResponseDTO
>;

import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartContourFeatureDTO } from '../models/report-part-contour-feature.dto';

export const PaginatedReportPartContourFeaturesDTO = PaginatedResponseRows(
  ReportPartContourFeatureDTO
);

export type PaginatedReportPartContourFeaturesDTO = z.infer<
  typeof PaginatedReportPartContourFeaturesDTO
>;

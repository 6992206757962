import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';
import _isEqual from 'lodash/isEqual';

export abstract class TransectStateService<T extends object> {
  private readonly _state: BehaviorSubject<T>;
  protected readonly state: Observable<T>;

  protected constructor(private initialState: T) {
    this._state = new BehaviorSubject<T>(initialState);
    this.state = this._state.asObservable();
  }

  select = <R>(projector: (v: T) => R): Observable<R> => {
    return this.state.pipe(
      map((val) => projector(val)),
      distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
    );
  };

  resetToInitialState() {
    this._state.next(this.initialState);
  }

  abstract registerEffects(): void;

  abstract unRegisterEffects(): void;

  get stateValue() {
    return this._state.getValue();
  }

  protected updateState(value: Partial<T>) {
    this._state.next({
      ...this.stateValue,
      ...value,
    });
  }
}

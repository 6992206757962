import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../utils/paginated-response-rows';
import { Sentiment } from '../../utils/sentiment-enum';
import { ProjectStatus } from '../projects';

export enum SolarPulseStatus {
  NOT_RENEWABLE = 'not-renewable',
  NOT_AVAILABLE = 'not-available',
  AVAILABLE = 'available',
  MULTIPLE_JURISDICTIONS = 'multiple-jurisdictions',
}

export const SiteComparisonDTO = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  projects: z.array(
    z.object({
      _id: z.string().uuid(),
    })
  ),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullish(),
});

export type SiteComparisonDTO = z.infer<typeof SiteComparisonDTO>;

export const SiteComparisonCreateDTO = z.object({
  name: z.string(),
  projectIds: z.array(z.string().uuid()).min(2, {
    message: 'At least 2 projects are required for comparison',
  }),
});

export type SiteComparisonCreateDTO = z.infer<typeof SiteComparisonCreateDTO>;

export const PublicConcernLevelEnum = z.enum(['low', 'moderate', 'high']);

export type PublicConcernLevelEnum = z.infer<typeof PublicConcernLevelEnum>;

export const SiteComparisonProjectInfoDTO = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  deleted_at: DateSchema.nullish(),
  status: z.nativeEnum(ProjectStatus).nullish(),
  geometryType: z.string().nullish(),
  size: z
    .object({
      area_acres: z.number().optional(),
      length_miles: z.number().optional(),
    })
    .optional(),
  nda: z.object({ area: z.number(), percentage: z.number() }).optional(),
  parcel_count: z.number().optional(),
  owner_count: z.number().optional(),
  permit_count: z.number().optional(),
  distance_to_nearest_transmission_line: z.number().nullish(),
  distance_to_nearest_substation: z.number().nullish(),
  solar_pulse: z
    .object({
      sentiment: Sentiment.optional(),
      status: z.nativeEnum(SolarPulseStatus),
    })
    .optional(),
  concern_levels: z
    .object({
      environmental_compliance: PublicConcernLevelEnum.optional(),
      protected_area: PublicConcernLevelEnum.optional(),
      species: PublicConcernLevelEnum.optional(),
      waters: PublicConcernLevelEnum.optional(),
    })
    .optional(),
});

export type SiteComparisonProjectInfoDTO = z.infer<
  typeof SiteComparisonProjectInfoDTO
>;

export const SiteComparisonCompareDTO = z.object({
  projectIds: z.array(z.string().uuid()).min(2),
});

export type SiteComparisonCompareDTO = z.infer<typeof SiteComparisonCompareDTO>;

export const SiteComparisonCompareResponseDTO = z.object({
  comparisons: z.array(SiteComparisonProjectInfoDTO),
});

export type SiteComparisonCompareResponseDTO = z.infer<
  typeof SiteComparisonCompareResponseDTO
>;

export const MySiteComparisonElement = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  creator__id: z.string().uuid(),
  projectIds: z.array(z.string()),
  states: z.array(z.string()),
  counties: z.array(z.string()),
});

export const GetMySiteComparisonsQueryDTO = z.object({
  page: pageNumber,
  pageSize: pageSize.default('50'),
  sortModel: sortModel.optional(),
});

export type GetMySiteComparisonsQueryDTO = z.infer<
  typeof GetMySiteComparisonsQueryDTO
>;

export type MySiteComparisonElement = z.infer<typeof MySiteComparisonElement>;

export const GetMySiteComparisonsResponseDTO = z.object({
  comparisons: z.array(MySiteComparisonElement),
});

export type GetMySiteComparisonsResponseDTO = z.infer<
  typeof GetMySiteComparisonsResponseDTO
>;

export const GetASiteComparisonResponseDTO = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  projectIds: z.array(z.string().uuid()),
});

export type GetASiteComparisonResponseDTO = z.infer<
  typeof GetASiteComparisonResponseDTO
>;

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpInternalServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private alertService: AlertService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (!(error instanceof HttpErrorResponse)) {
          return throwError(() => error as unknown);
        }

        if (error.status === 500) {
          const errorMessage =
            this.authService.currentUser?.role === 'public-user'
              ? 'Please contact <a href="mailto:marketplace@transect.com">marketplace@transect.com</a>.'
              : 'Please contact <a href="mailto:support@transect.com">support@transect.com</a>.';
          this.alertService.showError(errorMessage);
          return EMPTY;
        }

        return throwError(() => error);
      })
    );
  }
}

import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import _forEach from 'lodash/forEach';

@Directive({
  selector: '[tsFormLoader]',
})
export class FormLoaderDirective implements AfterViewInit, OnChanges {
  @Input()
  loaderState = false;

  @Input()
  emitEvent = true;

  @Input()
  defaultDisabledControls: string[] = [];

  constructor(
    private targetEl: ElementRef<HTMLFormElement>,
    private renderer: Renderer2,
    private readonly formGroupDirective: FormGroupDirective
  ) {}

  ngAfterViewInit(): void {
    const buttons = this.targetEl.nativeElement.querySelectorAll('button');

    if (this.loaderState) {
      this.formGroupDirective.form.disable({
        emitEvent: this.emitEvent,
      });
    }

    buttons.forEach((button) => {
      this.updateStateOfSquareButton(button);

      const loadingIcon = this.renderer.createElement('i') as HTMLElement;

      this.renderer.setStyle(
        loadingIcon,
        'display',
        this.loaderState ? 'inline-block' : 'none'
      );

      this.renderer.addClass(loadingIcon, 'fad');
      this.renderer.addClass(loadingIcon, 'fa-spinner-third');
      this.renderer.addClass(loadingIcon, 'spin-animation');
      this.renderer.setStyle(loadingIcon, 'padding', '0px 0.2em');

      this.renderer.appendChild(button, loadingIcon);

      if (this.loaderState) {
        button.disabled = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loaderState) {
      if (changes.loaderState.isFirstChange()) {
        this.initializeDefaultDisabledControls();
      }

      if (this.loaderState) {
        this.initializeDefaultDisabledControls();

        this.formGroupDirective.form.disable({
          emitEvent: this.emitEvent,
        });
      } else {
        this.formGroupDirective.form.enable({
          emitEvent: this.emitEvent,
        });

        this.defaultDisabledControls.forEach((control) =>
          this.formGroupDirective.form.get(control)?.disable({
            emitEvent: this.emitEvent,
          })
        );
      }

      const buttons = this.targetEl.nativeElement.querySelectorAll('button');

      buttons.forEach((button) => {
        if (this.loaderState) {
          button.disabled = true;
        } else {
          button.disabled = false;
        }

        if (button.classList.contains('no-spinner')) {
          return;
        }

        this.updateStateOfSquareButton(button);

        const loadingIcon = button.querySelector('.fa-spinner-third');
        if (loadingIcon) {
          this.renderer.setStyle(
            loadingIcon,
            'display',
            this.loaderState ? 'inline-block' : 'none'
          );
        }
      });
    }
  }

  private initializeDefaultDisabledControls(): void {
    this.defaultDisabledControls = [];
    _forEach(this.formGroupDirective.form.controls, (control, key) => {
      if (control.disabled) {
        this.defaultDisabledControls.push(key);
      }
    });
  }

  private updateStateOfSquareButton(button: HTMLButtonElement) {
    const rect = button.getBoundingClientRect();
    if (rect.width === rect.height) {
      for (let i = 0; i < button.children.length; i++) {
        const child = button.children.item(i);

        if (!child) {
          continue;
        }

        this.renderer.setStyle(
          child,
          'display',
          this.loaderState ? 'none' : 'inline-block'
        );
      }
    }
  }
}

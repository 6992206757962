import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export enum ReferenceCategory {
  PermitsAndPathways = 'permits & pathways',
  Species = 'species',
  Waters = 'waters',
  ProtectedAreas = 'protected areas',
  Environmental = 'environmental',
  Other = '',
}

const Reference = z.object({
  _id: z.string().uuid(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  category: z.nativeEnum(ReferenceCategory).nullable(),
  url_original_file: z.string().optional().nullable(),
  url_original_intro: z.string().optional().nullable(),
  url_transect: z.string().optional().nullable(),
  active: z.boolean().optional().nullable(),
  primary_source_active: z.boolean().optional().nullable(),
  date_gathered: DateSchema.optional().nullable(),
  properties: z.any().optional().nullable(),
  gcs: z.any().optional().nullable(),
  geography__id: z.string().optional().nullable(),
  parent__id: z.string().optional().nullable(),
  status_expert: z.string().optional().nullable(),
  review_by: DateSchema.optional().nullable(),
  catalog_references: z
    .object({
      _id: z.string().uuid().nullable().optional(),
    })
    .optional()
    .nullable(),
  creator: z.preprocess(
    (val) => (typeof val === 'string' ? { _id: val } : val),
    z
      .object({
        _id: z.string().uuid(),
        fullname: z.string().optional().nullable(),
        firstname: z.string().optional().nullable(),
        lastname: z.string().optional().nullable(),
      })
      .optional()
      .nullable()
  ),
  links: z
    .array(
      z.object({
        name: z.string(),
        url: z.string(),
      })
    )
    .nullish(),
});

export const ReferenceDTO = Reference;

export type ReferenceDTO = z.infer<typeof ReferenceDTO>;

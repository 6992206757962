import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { GeographyDTO } from '../../geography';
import { RegulationDTO } from '../regulation-dto';

export const RegulationPaginatedTreeResponseRows = PaginatedResponseRows(
  RegulationDTO.pick({
    _id: true,
    name: true,
    acronym: true,
    nameAcronymBack: true,
    jurisdiction: true,
    topic: true,
    display_criteria: true,
    content: true,
    status_expert: true,
    triggers: true,
    refresh_threshold: true,
    last_refresh_date: true,
    region: true,
    owner__id: true,
    active: true,
    created_at: true,
    updated_at: true,
    review_by: true,
  }).merge(
    z.object({
      regulation_location_count: z.number().default(0),
      regulation_permit_count: z.number().nullable(),
      hasChildren: z.boolean().default(false),
      geography: GeographyDTO.pick({
        _id: true,
        name: true,
        type: true,
      }).nullable(),
      regulator: z
        .object({
          _id: z.string().uuid(),
          name: z.string().nullish(),
          acronym: z.string().nullish(),
          nameAcronymBack: z.string().nullish(),
        })
        .nullable(),
      customer: z
        .object({
          _id: z.string().uuid(),
          name: z.string().nullable(),
        })
        .nullable(),
      broken_links_count: z.number(),
    })
  )
);

export type RegulationPaginatedTreeResponseRows = z.infer<
  typeof RegulationPaginatedTreeResponseRows
>;

import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const EnvironmentalComplianceProperties = z.object({
  air_ids: z.string().nullable(),
  dfr_url: z.string(),
  fac_lat: z.preprocess((val) => (val ? Number(val) : val), z.number()),
  fac_zip: z.union([z.string(), z.number()]).nullable(),
  ghg_ids: z.union([z.string(), z.number()]).nullable(),
  tri_ids: z.string().nullable(),
  air_flag: z.string(),
  caa_sics: z.union([z.string(), z.number()]).nullable(),
  cwa_sics: z.string().nullable(),
  fac_city: z.string().nullable(),
  fac_long: z.preprocess((val) => (val ? Number(val) : val), z.number()),
  fac_name: z.string(),
  ghg_flag: z.string(),
  rcra_ids: z.string().nullable(),
  sdwa_ids: z.string().nullable(),
  tri_flag: z.string(),
  caa_naics: z.string().nullable(),
  cwa_naics: z.union([z.string(), z.number()]).nullable(),
  fac_state: z.string(),
  npdes_ids: z.string().nullable(),
  rcra_flag: z.string(),
  fac_county: z.string().nullable(),
  fac_street: z.string().nullable(),
  npdes_flag: z.string(),
  rcra_naics: z.string().nullable(),
  sdwis_flag: z.string(),
  fac_pop_den: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_snc_flg: z.string(),
  registry_id: z.string(),
  caa_hpv_flag: z.string(),
  cwa_snc_flag: z.string(),
  fac_map_icon: z.string(),
  fac_naa_flag: z.string(),
  fec_case_ids: z.string().nullable(),
  tri_reporter: z.string().nullable(),
  caa_penalties: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  cwa_penalties: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_fips_code: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_sic_codes: z.string().nullable(),
  rcra_snc_flag: z.string(),
  sdwa_snc_flag: z.string(),
  fac_epa_region: z.preprocess((val) => (val ? Number(val) : val), z.number()),
  fac_major_flag: z.string().nullable(),
  rcra_penalties: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_active_flag: z.string().nullable(),
  fac_derived_huc: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_derived_wbd: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_derived_zip: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_federal_flg: z.string().nullable(),
  fac_naics_codes: z.string().nullable(),
  caa_permit_types: z.string().nullable(),
  caa_qtrs_with_nc: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  cwa_permit_types: z.string().nullable(),
  cwa_qtrs_with_nc: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  ejscreen_flag_us: z.string().nullable(),
  fac_qtrs_with_nc: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  ghg_co2_releases: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_derived_cd113: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_imp_water_flg: z.string().nullable(),
  fac_penalty_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  rcra_permit_types: z.string().nullable(),
  rcra_qtrs_with_nc: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  sdwa_system_types: z.string().nullable(),
  caa_informal_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  cwa_informal_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_derived_cb2010: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_derived_tribes: z.string().nullable(),
  fac_federal_agency: z.string().nullable(),
  fac_informal_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number()
  ),
  fac_myrtk_universe: z.string(),
  fec_last_case_date: z.string().nullable(),
  cwa_3_yr_qncr_codes: z.string().nullable(),
  fac_accuracy_meters: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_reference_point: z.string().nullable(),
  fac_total_penalties: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number()
  ),
  fec_number_of_cases: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fec_total_penalties: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  rcra_informal_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  sdwa_informal_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  caa_evaluation_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  caa_last_penalty_amt: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  cwa_inspection_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  cwa_last_penalty_amt: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_indian_cntry_flg: z.string(),
  fac_inspection_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number()
  ),
  fac_last_penalty_amt: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_percent_minority: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  tri_on_site_releases: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  tri_reporter_in_past: z.string().nullable(),
  caa_compliance_status: z.string().nullable(),
  caa_last_penalty_date: z.string().nullable(),
  cwa_compliance_status: z.string().nullable(),
  cwa_last_penalty_date: z.string().nullable(),
  fac_collection_method: z.string().nullable(),
  fac_compliance_status: z.string().nullable(),
  fac_date_last_penalty: z.string().nullable(),
  fac_programs_with_snc: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number()
  ),
  fac_us_mex_border_flg: z.string().nullable(),
  rcra_inspection_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  rcra_last_penalty_amt: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_chesapeake_bay_flg: z.string().nullable(),
  fac_derived_stcty_fips: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  rcra_compliance_status: z.string().nullable(),
  rcra_last_penalty_date: z.string().nullable(),
  sdwa_compliance_status: z.string().nullable(),
  tri_off_site_transfers: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  tri_releases_transfers: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  caa_formal_action_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  cwa_compliance_tracking: z.string().nullable(),
  cwa_formal_action_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_formal_action_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number()
  ),
  caa_days_last_evaluation: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  cwa_13qtrs_compl_history: z.string().nullable(),
  cwa_days_last_inspection: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_date_last_inspection: z.string().nullable(),
  fac_days_last_inspection: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  rcra_formal_action_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  sdwa_formal_action_count: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  rcra_days_last_evaluation: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  caa_3yr_compl_qtrs_history: z.string().nullable(),
  fac_3yr_compliance_history: z.string().nullable(),
  caa_date_last_formal_action: z.string().nullable(),
  cwa_date_last_formal_action: z.string().nullable(),
  fac_date_last_formal_act_st: z.string().nullable(),
  fac_date_last_formal_action: z.string().nullable(),
  rcra_3yr_compl_qtrs_history: z.string().nullable(),
  fac_date_last_formal_act_epa: z.string().nullable(),
  fac_date_last_inspection_epa: z.string().nullable(),
  rcra_date_last_formal_action: z.string().nullable(),
  cwa_13qtrs_efflnt_exceedances: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable()
  ),
  fac_date_last_informal_act_st: z.string().nullable(),
  fac_date_last_informal_action: z.string().nullable(),
  fac_date_last_informal_act_epa: z.string().nullable(),
  fac_date_last_inspection_state: z.string().nullable(),
});

const EnvironmentalCompliance = z.object({
  _id: z.string().uuid(),
  name: z.string().nullable(),
  address: z.string().nullable(),
  fac_name: z.string().nullable(),
  registry_id: z.string().nullable(),
  properties: EnvironmentalComplianceProperties.deepPartial(),
  geometry: GeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('environmental_compliance'),
  transect_summary: TransectSummarySchema,
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
});

export const ReportPartEnvironmentalComplianceDTO = EnvironmentalCompliance;

export type ReportPartEnvironmentalComplianceDTO = z.infer<
  typeof ReportPartEnvironmentalComplianceDTO
>;

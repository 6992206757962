import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { MapModule } from '../map.module';

@Injectable()
export class DynamicComponentService {
  private compRefs: { [key: number]: ComponentRef<any> } = {};

  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {}

  public injectComponent<T>(
    component: Type<T>,
    propertySetter?: (type: T) => void
  ): {
    element: HTMLDivElement;
    key: number;
    componentRef: ComponentRef<T>;
  } {
    const compFactory = this.resolver.resolveComponentFactory(component);
    let key = 0;
    if (Object.keys(this.compRefs).length) {
      key =
        Math.max(
          ...Object.keys(this.compRefs).map((compKey) => parseInt(compKey, 10))
        ) + 1;
    }
    this.compRefs[key] = compFactory.create(this.injector);

    if (propertySetter) {
      propertySetter(this.compRefs[key].instance);
    }

    this.appRef.attachView(this.compRefs[key].hostView);

    const div = document.createElement('div');
    div.appendChild(this.compRefs[key].location.nativeElement);

    return {
      element: div,
      key,
      componentRef: this.compRefs[key],
    };
  }

  public destroy(key: number): void {
    const ref = this.compRefs[key];

    if (ref) {
      ref.destroy();
      delete this.compRefs[key];
    }
  }

  public destroyAll(): void {
    for (const [key, ref] of Object.entries(this.compRefs)) {
      ref.destroy();
    }

    this.compRefs = {};
  }
}

import { z } from 'zod';

export const AssignTagsResponseDTO = z.array(
  z.object({
    entityId: z.string().uuid(),
    tagId: z.string().uuid(),
  })
);

export type AssignTagsResponseDTO = z.infer<typeof AssignTagsResponseDTO>;

import { Component } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export type ICellRendererParamsWithSelectOptions<
  DataType,
  OptionType extends { value: string; disabled: boolean; name: string } = {
    value: string;
    disabled: boolean;
    name: string;
  }
> = Omit<ICellRendererParams, 'data'> & {
  options: OptionType[] | ((data?: DataType) => OptionType[]);
  onSelectionChange: (event: MatSelectChange, data: DataType) => void;
  disabled: boolean | ((data: DataType) => boolean);
  disabledMessage: string | null;
  data: DataType;
};

@Component({
  selector: 'ts-select-input-cell-renderer',
  templateUrl: './select-input-cell-renderer.component.html',
  styleUrls: ['./select-input-cell-renderer.component.scss'],
})
export class SelectInputCellRendererComponent<
  DataType,
  OptionType extends { value: string; disabled: boolean; name: string } = {
    value: string;
    disabled: boolean;
    name: string;
  }
> implements ICellRendererAngularComp
{
  params?: ICellRendererParamsWithSelectOptions<DataType, OptionType>;
  options: OptionType[] = [];

  onSelectionChange?: (event: MatSelectChange, data: DataType) => void;
  disabled?: boolean;
  isDisabled = false;
  disabledMessage: string | null = null;

  agInit(
    params: ICellRendererParamsWithSelectOptions<DataType, OptionType>
  ): void {
    this.params = params;
    this.disabledMessage = params.disabledMessage;
    this.onSelectionChange = params.onSelectionChange;

    if (params.options instanceof Function) {
      this.options = params.options(params.data);
    } else {
      this.options = params.options || [];
    }

    if (params.disabled instanceof Function) {
      this.isDisabled = params.disabled(params.data);
    } else {
      this.isDisabled = params.disabled || false;
    }
  }

  refresh(params: unknown): boolean {
    return true;
  }

  change(event: MatSelectChange) {
    if (this.params?.data && this.onSelectionChange) {
      this.onSelectionChange(event, this.params.data);
    }
  }
}

import { z } from 'zod';

const ReportPartRegulation = z.object({
  _id: z.string().uuid(),
  parent__id: z.string().uuid().nullish(),
  name: z.string().nullish(),
  nameAcronymBack: z.string().nullish(),
  descriptor: z.string().nullish(),
  jurisdiction: z.string().nullish(),
  customer__id: z.string().uuid().nullish(),
  content: z
    .object({
      intro: z.string().nullish(),
      body: z.string().nullish(),
      recommendation: z.string().nullish(),
      implications: z.string().nullish(),
    })
    .nullish(),
  links: z
    .array(
      z.object({
        name: z.string().nullish(),
        url: z.string().nullish(),
      })
    )
    .nullish(),
  data_gap: z.boolean().default(false),
  geography: z
    .object({
      _id: z.string().uuid(),
      name: z.string().nullish(),
      abbreviation: z.string().nullish(),
    })
    .nullish(),
  references: z.any().nullish(),
  regulator__id: z.string().uuid().nullish(),
  regulator: z.any().nullish(),
  assessment: z.any().nullish(),
  count_recommendations: z.preprocess(
    (value) => Number(value),
    z.number().default(0)
  ),
  has_trigger_recommendations: z.boolean().default(false),
  active_triggers: z.any().nullish(),
  permits: z.any().nullish(),
  topic: z.string().nullish(),
});

export const ReportPartRegulationDTO = ReportPartRegulation;

export type ReportPartRegulationDTO = z.infer<typeof ReportPartRegulationDTO>;

export const ReportPartActiveRegulationDTO = ReportPartRegulation.omit({
  geography: true,
  count_recommendations: true,
  references: true,
  permits: true,
});

export type ReportPartActiveRegulationDTO = z.infer<
  typeof ReportPartActiveRegulationDTO
>;

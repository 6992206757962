import { Injectable } from '@angular/core';
import JSZip from 'jszip';
import { from, Observable, of, Subscriber } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileReaderService {
  constructor() {}

  getExtension(fileName: string): string {
    return fileName.split('.').pop();
  }
  readFileAsText(file: File): Observable<string> {
    const ext = this.getExtension(file.name);

    if (ext === 'kmz') {
      const zip = new JSZip();

      return from(zip.loadAsync(file)).pipe(
        switchMap((unzipped) => {
          let kmlString: Promise<string> = null;
          unzipped.forEach((relPath, f) => {
            if (this.getExtension(relPath) === 'kml' && kmlString === null) {
              kmlString = f.async('string');
            }
          });
          return from(kmlString);
        })
      );
    }

    if (file) {
      const fileReader = new FileReader();

      return new Observable((observer: Subscriber<string>): void => {
        fileReader.onload = (ev): void => {
          observer.next(ev.target.result as string);
          observer.complete();
        };
        fileReader.onerror = (error): void => {
          observer.error(error);
        };

        fileReader.readAsText(file);
      });
    }

    return of(null);
  }
}

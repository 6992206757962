import { z } from 'zod';
import { OrderEnum } from '../../../utils/order-enum';
import { pageNumber, pageSize } from '../../../utils/paginated-response-rows';

export const UserControllerPaginateQueryParams = z.object({
  search: z.string().nullish(),
  _id: z.preprocess((val) => val || null, z.string().uuid().nullish()),
  customerId: z.preprocess((val) => val || null, z.string().uuid().nullish()),
  role: z.preprocess((val) => val || null, z.string().nullish()),
  includeDeletedUsers: z.preprocess((val) => Boolean(val), z.boolean()),
  sortModel: z
    .preprocess(
      (val) => {
        const isNonEmptyString = typeof val === 'string' && val;
        return isNonEmptyString ? JSON.parse(val) : val || [];
      },
      z.array(
        z.object({
          colId: z.string(),
          sort: OrderEnum,
        })
      )
    )
    .optional(),
  page: pageNumber,
  limit: pageSize,
});

export type UserControllerPaginateQueryParams = z.infer<
  typeof UserControllerPaginateQueryParams
>;

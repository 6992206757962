import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartCatalogItemDTO } from '../models/report-part-catalog-item.dto';

export const PaginatedReportPartCatalogItemsDTO = PaginatedResponseRows(
  ReportPartCatalogItemDTO
);

export type PaginatedReportPartCatalogItemsDTO = z.infer<
  typeof PaginatedReportPartCatalogItemsDTO
>;

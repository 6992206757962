import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { filter, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import { FormModalInputData } from '../../models/form-modal.service';

@Component({
  selector: 'ts-form-user-free-trial-agreement',
  templateUrl: './form-user-free-trial-agreement.component.html',
  styleUrls: ['./form-user-free-trial-agreement.component.scss'],
})
export class FormUserFreeTrialAgreementComponent implements OnInit, OnDestroy {
  private readonly destroyAction = new Subject<void>();

  freeTrialAgreementForm = new FormGroup({
    agreed: new FormControl(false, [Validators.requiredTrue]),
  });

  get formStateService() {
    return this.data.stateService;
  }

  isComplete$ = this.formStateService.isComplete$;
  canSave$ = this.formStateService.canSave$;
  inProgress$ = this.formStateService.inProgress$;
  completing$ = this.formStateService.completing$;
  isFetchingPdf$ = this.formStateService.isFetchingPdf$;
  saveButtonColor$ = this.formStateService.saveButtonColor$;

  constructor(
    private dialogRef: MatDialogRef<FormUserFreeTrialAgreementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormModalInputData
  ) {}

  ngOnInit(): void {
    this.formStateService.registerEffects();

    if (this.formStateService.stateValue.isComplete) {
      this.freeTrialAgreementForm.disable();
    }

    this.formStateService.formValue$
      .pipe(
        take(1),
        tap((formValue) => {
          this.freeTrialAgreementForm.patchValue(formValue, {
            emitEvent: false,
          });
        })
      )
      .subscribe();

    this.freeTrialAgreementForm.valueChanges
      .pipe(
        filter(() => this.freeTrialAgreementForm.dirty),
        switchMap((formValue: FormDTO['contents']) => {
          return this.formStateService.dispatchFormValueChangeAction(
            this.freeTrialAgreementForm.valid,
            formValue,
            this.data.formTypeId
          );
        }),
        takeUntil(this.destroyAction)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyAction.next();
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  handleSaveClick() {
    if (!this.freeTrialAgreementForm.valid) {
      return;
    }
    this.formStateService.dispatchCompleteFormAction().subscribe(() => {
      this.dialogRef.close();
    });
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }
}

import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import {
  ReportPartPermitDTO,
  ReportPartActivePermitDTO,
} from '../models/report-part-permit.dto';

export const PaginatedReportPartPermitsDTO =
  PaginatedResponseRows(ReportPartPermitDTO);

export type PaginatedReportPartPermitsDTO = z.infer<
  typeof PaginatedReportPartPermitsDTO
>;

export const PaginatedReportPartActivePermitsDTO = PaginatedResponseRows(
  ReportPartActivePermitDTO
);

export type PaginatedReportPartActivePermitsDTO = z.infer<
  typeof PaginatedReportPartActivePermitsDTO
>;

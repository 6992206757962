import { z } from 'zod';
import { stringToNumber } from '../../../utils/string-to-number';

export const ReferencesSearchQueryDTO = z.object({
  page: z
    .preprocess(
      (val) => (typeof val === 'string' ? stringToNumber(val) : val),
      z.number()
    )
    .default(1),
  pageSize: z
    .preprocess(
      (val) => (typeof val === 'string' ? stringToNumber(val) : val),
      z.number()
    )
    .default(100),
  sortBy: z.string().default('title'),
  order: z
    .string()
    .transform((value) => value.toUpperCase())
    .default('ASC'),
  _id: z.string().optional(),
  title: z.string().optional(),
});

export type ReferencesSearchQueryDTO = z.infer<typeof ReferencesSearchQueryDTO>;

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-046019 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Transect LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Transect App )_only_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Transect App )_need_to_be_licensed___( Transect App )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 October 2024 )____[v2]_MTcyODg2MDQwMDAwMA==195d5e9ff72e89948e80356410d16834'
);

if (environment.production) {
  enableProdMode();
}

if (
  window.self !== window.top &&
  !['/1-click', '/report-sample', '/users/free_trial'].includes(
    window.location.pathname
  )
) {
  document.body.innerHTML =
    '<h1>This page cannot be displayed in an iframe.</h1>';
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

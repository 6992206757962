import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import {
  AssessmentLevels,
  MonitoringDataPointCategory,
  MonitoringDataPointKey,
  ReportMonitoredDataPointChangelogMap,
} from '@transect-nx/models';

const BaseLog = z.object({
  calculated_at: DateSchema,
  category: z.nativeEnum(MonitoringDataPointCategory),
  key: z.nativeEnum(MonitoringDataPointKey),
  log: z.object({
    value: z.object({}),
    changes: z.object({}).nullable(),
  }),
});

const ChangelogMap: {
  [key in MonitoringDataPointKey]: z.ZodType<
    ReportMonitoredDataPointChangelogMap[key]
  >;
} = {
  [MonitoringDataPointKey.PERMIT_ADDED_REMOVED]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PERMIT_ADDED_REMOVED),
    category: z.literal(MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS),
    log: z.object({
      value: z.object({
        active_permits: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
          })
        ),
      }),
      changes: z
        .object({
          permits_added: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
            })
          ),
          permits_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PERMIT_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.PERMIT_REGULATIONS]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PERMIT_REGULATIONS),
    category: z.literal(MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS),
    log: z.object({
      value: z.object({
        permit_regulations: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
            regulations: z.array(
              z.object({
                _id: z.string().uuid(),
                name: z.string(),
              })
            ),
          })
        ),
      }),
      changes: z
        .object({
          permit_regulations: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
              regulations_added: z.array(
                z.object({
                  _id: z.string().uuid(),
                  name: z.string(),
                })
              ),
              regulations_removed: z.array(
                z.object({
                  _id: z.string().uuid(),
                  name: z.string(),
                })
              ),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PERMIT_REGULATIONS]
  >,
  [MonitoringDataPointKey.PERMIT_TIMELINE]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.PERMIT_TIMELINE),
    category: z.literal(MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS),
    log: z.object({
      value: z.object({
        permit_timelines: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
            timeline: z.string(),
          })
        ),
      }),
      changes: z
        .object({
          permit_timelines_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
              new_timeline: z.string(),
              old_timeline: z.string(),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PERMIT_TIMELINE]
  >,
  [MonitoringDataPointKey.REGULATION_ADDED_REMOVED]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.REGULATION_ADDED_REMOVED),
    category: z.literal(
      MonitoringDataPointCategory.FEDERAL_AND_STATE_REGULATIONS
    ),
    log: z.object({
      value: z.object({
        active_regulations: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
          })
        ),
      }),
      changes: z
        .object({
          regulations_added: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
            })
          ),
          regulations_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.REGULATION_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.REGULATION_PERMITS]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.REGULATION_PERMITS),
    category: z.literal(
      MonitoringDataPointCategory.FEDERAL_AND_STATE_REGULATIONS
    ),
    log: z.object({
      value: z.object({
        regulation_permits: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
            permits: z.array(
              z.object({
                _id: z.string().uuid(),
                name: z.string(),
              })
            ),
          })
        ),
      }),
      changes: z
        .object({
          regulation_permits: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
              permits_added: z.array(
                z.object({
                  _id: z.string().uuid(),
                  name: z.string(),
                })
              ),
              permits_removed: z.array(
                z.object({
                  _id: z.string().uuid(),
                  name: z.string(),
                })
              ),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.REGULATION_PERMITS]
  >,
  [MonitoringDataPointKey.SPECIES_ADDED_REMOVED]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.SPECIES_ADDED_REMOVED),
    category: z.literal(MonitoringDataPointCategory.SPECIES_DATA),
    log: z.object({
      value: z.object({
        species: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
          })
        ),
      }),
      changes: z
        .object({
          species_added: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
            })
          ),
          species_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.SPECIES_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED),
    category: z.literal(MonitoringDataPointCategory.SPECIES_DATA),
    log: z.object({
      value: z.object({
        species: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
          })
        ),
      }),
      changes: z
        .object({
          species_added: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
            })
          ),
          species_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.SPECIES_CONCERN_LEVEL]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.SPECIES_CONCERN_LEVEL),
    category: z.literal(MonitoringDataPointCategory.SPECIES_DATA),
    log: z.object({
      value: z.object({
        species: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            assessment: z.nativeEnum(AssessmentLevels),
          })
        ),
      }),
      changes: z
        .object({
          species_concern_levels_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              new_assessment: z.nativeEnum(AssessmentLevels),
              old_assessment: z.nativeEnum(AssessmentLevels),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.SPECIES_CONCERN_LEVEL]
  >,
  [MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL),
    category: z.literal(MonitoringDataPointCategory.SPECIES_DATA),
    log: z.object({
      value: z.object({
        species: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            assessment: z.nativeEnum(AssessmentLevels),
          })
        ),
      }),
      changes: z
        .object({
          species_concern_levels_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              new_assessment: z.nativeEnum(AssessmentLevels),
              old_assessment: z.nativeEnum(AssessmentLevels),
            })
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL]
  >,
  [MonitoringDataPointKey.REPORT_GENERATED_AT]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.REPORT_GENERATED_AT),
    category: z.literal(MonitoringDataPointCategory.REPORT_STATS),
    log: z.object({
      value: z.object({
        generated_at: z.string(),
      }),
      changes: z
        .object({
          old_generated_at: z.string(),
          new_generated_at: z.string(),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.REPORT_GENERATED_AT]
  >,
} as const;

const Changelog = z.union([
  ChangelogMap[MonitoringDataPointKey.PERMIT_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.PERMIT_TIMELINE],
  ChangelogMap[MonitoringDataPointKey.REGULATION_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.SPECIES_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.SPECIES_CONCERN_LEVEL],
  ChangelogMap[MonitoringDataPointKey.PERMIT_REGULATIONS],
  ChangelogMap[MonitoringDataPointKey.REGULATION_PERMITS],
  ChangelogMap[MonitoringDataPointKey.REPORT_GENERATED_AT],
  ChangelogMap[MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL],
]);

export type Changelog = z.infer<typeof Changelog>;

export const ChangelogsResponse = z.object({
  last_viewed_at: DateSchema.nullable(),
  changelogs: z.array(Changelog),
});

export type ChangelogsResponse = z.infer<typeof ChangelogsResponse>;

const ReportId = z.object({
  reportId: z.string().uuid(),
});

export const ChangelogsParams = ReportId;

export type ChangelogsParams = z.infer<typeof ChangelogsParams>;

export const ToggleMonitorParams = ReportId;

export type ToggleMonitorParams = z.infer<typeof ChangelogsParams>;

export const ToggleMonitorBody = z.union([
  z.object({
    monitoring_data_points: z.array(
      z.object({
        active: z.boolean(),
        key: z.nativeEnum(MonitoringDataPointKey),
      })
    ),
  }),
  z.object({
    action: z.enum(['enable_all', 'disable_all']),
  }),
]);

export type ToggleMonitorBody = z.infer<typeof ToggleMonitorBody>;

export const GetMonitoredDataPointsParams = ReportId;

export type GetMonitoredDataPointsParams = z.infer<
  typeof GetMonitoredDataPointsParams
>;

export const GetMonitoredDataPointsResponse = z.array(
  z.object({
    key: z.nativeEnum(MonitoringDataPointKey),
    category: z.nativeEnum(MonitoringDataPointCategory),
    active: z.boolean(),
  })
);

export type GetMonitoredDataPointsResponse = z.infer<
  typeof GetMonitoredDataPointsResponse
>;

export const ChangesSinceLastRefreshParams = ReportId;

export type ChangesSinceLastRefreshParams = z.infer<
  typeof ChangesSinceLastRefreshParams
>;

export const ChangesSinceLastRefreshResponse = z.object({
  last_refreshed_at: DateSchema.nullable(),
  change_count_since_last_refresh: z.number(),
});

export type ChangesSinceLastRefreshResponse = z.infer<
  typeof ChangesSinceLastRefreshResponse
>;

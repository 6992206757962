import { z } from 'zod';
import { PointGeometrySchema } from '../../utils/geometry-object-schema';

export const GetProjectsIdSiteAccessInfoResponseDTO = z.object({
  aoi_center: PointGeometrySchema,
  county_states: z.array(
    z.object({
      state: z.string(),
      counties: z.array(z.string()),
    })
  ),
  parcel_numbers: z.array(z.string()),
});

export type GetProjectsIdSiteAccessInfoResponseDTO = z.infer<
  typeof GetProjectsIdSiteAccessInfoResponseDTO
>;

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommentObjectType,
  CommentsResponseRowsDTO,
} from '@transect-nx/data-transfer-objects';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CommentWithEditing } from '../../models/comment';

@Injectable({
  providedIn: 'root',
})
export class CommentsApiService {
  private baseURL = `${environment.apiUrl}/comments`;

  constructor(private http: HttpClient) {}

  fetchComments(
    objectType: CommentObjectType,
    objectId: string,
    order: 'asc' | 'desc' = 'asc',
    page: number = 1,
    pageSize: number = 100,
    sortBy: string = 'created_at'
  ): Observable<CommentsResponseRowsDTO> {
    const params = new HttpParams({
      fromObject: {
        object__type: objectType,
        object__id: objectId,
        order: order,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
      },
    });
    return this.http.get<CommentsResponseRowsDTO>(this.baseURL, { params });
  }

  postComment(commentToPost: {
    object__id: string;
    object__type: string;
    properties: {
      text: string;
    };
  }): Observable<CommentWithEditing> {
    return this.http.post<CommentWithEditing>(this.baseURL, commentToPost);
  }

  patchComment(commentId: string, updatedComment: string): Observable<void> {
    return this.http.patch<void>(`${this.baseURL}/${commentId}`, {
      properties: {
        text: updatedComment,
      },
    });
  }

  deleteComment(commentId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseURL}/${commentId}`);
  }

  fetchCommentsCountByObjectTypeAndId(
    objectType: CommentObjectType,
    objectId: string
  ): Observable<number> {
    const params = new HttpParams()
      .set('objectType', objectType)
      .set('objectId', objectId);

    return this.http.get<number>(`${this.baseURL}/count`, {
      params,
    });
  }
}

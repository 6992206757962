import { z } from 'zod';
import { OrderEnum } from './order-enum';
import { jsonSchema } from './string-to-json';

export const PaginatedResponseRows = <T extends z.ZodTypeAny>(type: T) => {
  return z.object({
    count: z.number(),
    rows: z.array(type),
    page: z.number(),
    pageSize: z.number(),
    totalPages: z.number(),
  });
};

export type PaginatedResponseRows<Item extends object> = {
  count: number;
  rows: Item[];
  page: number;
  pageSize: number;
  totalPages: number;
};

export const sortModel = jsonSchema(
  z.array(
    z.object({
      colId: z.string(),
      sort: OrderEnum,
    })
  )
);

export const pageNumber = z.preprocess(
  (val) => val || '1',
  z.string().transform((val, ctx) => {
    const parsed = parseInt(val);
    if (isNaN(parsed)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `'page' must be an integer.`,
      });
    }
    return parsed;
  })
);

export const pageSize = z.preprocess(
  (val) => val || '20',
  z.string().transform((val, ctx) => {
    const parsed = parseInt(val);
    if (isNaN(parsed)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `'limit' must be an integer.`,
      });
    }
    return parsed;
  })
);

import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import {
  catchError,
  EMPTY,
  filter,
  finalize,
  interval,
  map,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { FormModalInputData } from '../../../../models/form-modal.service';
import { AlertService } from '../../../../services/alert.service';
import { ProjectService } from '../../../../services/project.service';

@Component({
  templateUrl: './form-marketplace-site-access-authorization.component.html',
  styleUrls: ['./form-marketplace-site-access-authorization.component.scss'],
  selector: 'ts-site-access-authorization-form',
})
export class FormMarketplaceSiteAccessAuthorizationComponent implements OnInit {
  private stopAnimation$ = new Subject<void>();

  private authorizationForm = new UntypedFormGroup({
    ownerOrOwnerAgentName: new UntypedFormControl('', [Validators.required]),
    propertyAccessDate: new UntypedFormControl('', [Validators.required]),
    userOrUserAgentName: new UntypedFormControl('', [Validators.required]),
    approverTitle: new UntypedFormControl('', [Validators.required]),
    approvalDate: new UntypedFormControl('', [Validators.required]),
    specialInstructions: new UntypedFormControl('', [Validators.required]),
    parcelIdentificationNumber: new UntypedFormControl(
      {
        value: '',
        disabled: true,
      },
      [Validators.required]
    ),
  });

  private dispatchFormValueChanges$ = this.authorizationForm.valueChanges.pipe(
    filter(() => this.authorizationForm.dirty),
    switchMap(() => {
      return this.formStateService.dispatchFormValueChangeAction(
        this.authorizationForm.valid,
        this.authorizationForm.getRawValue() as FormDTO['contents'],
        this.data.formTypeId,
        this.data.addOnId
      );
    })
  );

  private animatePlaceholderText$ = interval(500).pipe(
    tap(() => {
      const dotCount =
        this.view.state.loadingPlaceholderText.match(/./g).length % 6;

      this.view.state.loadingPlaceholderText = `Loading information${'.'.repeat(
        dotCount
      )}`;
    }),
    map(() => {
      return;
    }),
    takeUntil(this.stopAnimation$)
  );

  private prefillForm$ = of(null).pipe(
    switchMap(() => {
      const parcelIdentificationNumber =
        this.formStateService.stateValue.formValue?.[
          'parcelIdentificationNumber'
        ];

      if (parcelIdentificationNumber) {
        return EMPTY;
      }

      return this.projectService.getSiteAccessInfo(this.data.projectId).pipe(
        catchError((error) => {
          this.alertService.showError(error);

          return EMPTY;
        })
      );
    }),
    map((siteAccessInfo) => {
      const parcelNumbers = siteAccessInfo.parcel_numbers.join(', ');
      return {
        parcelIdentificationNumber: parcelNumbers,
      };
    }),
    tap(({ parcelIdentificationNumber }) => {
      this.view.state.authorizationForm
        .get('parcelIdentificationNumber')
        .setValue(parcelIdentificationNumber);
    }),
    map(() => {
      return;
    }),
    finalize(() => {
      this.stopAnimation$.next();
    })
  );

  get formStateService() {
    return this.data.stateService;
  }

  view = {
    state: {
      saveButtonColor$: this.formStateService.saveButtonColor$,
      canSave$: this.formStateService.canSave$,
      completing$: this.formStateService.completing$,
      inProgress$: this.formStateService.inProgress$,
      isComplete$: this.formStateService.isComplete$,
      authorizationForm: this.authorizationForm,
      loadingPlaceholderText: 'Loading information',
      isFetchingPdf$: this.formStateService.isFetchingPdf$,
    },
    actions: [
      this.dispatchFormValueChanges$,
      this.animatePlaceholderText$,
      this.prefillForm$,
    ],
  };

  constructor(
    private dialogRef: MatDialogRef<FormMarketplaceSiteAccessAuthorizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormModalInputData,
    private projectService: ProjectService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    if (this.formStateService.stateValue.formValue) {
      this.authorizationForm.patchValue(
        this.formStateService.stateValue.formValue,
        { emitEvent: false }
      );
      this.formStateService.dispatchFormValidAction(
        !this.authorizationForm.invalid
      );
    }

    if (this.formStateService.stateValue.isComplete || this.data.readOnly) {
      this.authorizationForm.disable();
    }
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  handleSaveClick() {
    if (!this.authorizationForm.valid) {
      return;
    }
    this.formStateService.dispatchCompleteFormAction().subscribe(() => {
      this.dialogRef.close();
    });
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }
}

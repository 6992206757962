import { z } from 'zod';
import { AuditLogDTO } from '../audit-log.dto';

export const AuditLogRow = AuditLogDTO.omit({
  columns_changed: true,
}).extend({
  columns_changed: z.record(
    z.object({
      new: z.any(),
      old: z.any(),
    })
  ),
  username: z.string(),
});
export type AuditLogRow = z.infer<typeof AuditLogRow>;

export const AuditLogsResponse = z.array(AuditLogRow);
export type AuditLogsResponse = z.infer<typeof AuditLogsResponse>;

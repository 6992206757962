import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const HydrographyProperties = z.object({
  fcode: z.string(),
  fdate: z.string(),
  ftype: z.string(),
  tonode: z.union([z.string(), z.number()]),
  dnlevel: z.union([z.string(), z.number()]),
  flowdir: z.string(),
  gnis_id: z.string(),
  areasqkm: z.number(),
  fromnode: z.union([z.string(), z.number()]),
  globalid: z.string(),
  hydroseq: z.union([z.string(), z.number()]),
  lengthkm: z.number(),
  mainpath: z.string(),
  objectid: z.number(),
  elevation: z.string(),
  gnis_name: z.string(),
  innetwork: z.string(),
  reachcode: z.string(),
  startflag: z.union([z.string(), z.number()]),
  resolution: z.string(),
  shape_area: z.number(),
  uphydroseq: z.union([z.string(), z.number()]),
  levelpathid: z.union([z.string(), z.number()]),
  streamlevel: z.union([z.string(), z.number()]),
  streamorder: z.union([z.string(), z.number()]),
  thinnercode: z.union([z.string(), z.number()]),
  dndraincount: z.union([z.string(), z.number()]),
  pathlengthkm: z.union([z.string(), z.number()]),
  shape_length: z.number(),
  terminalflag: z.union([z.string(), z.number()]),
  arbolatesumkm: z.union([z.string(), z.number()]),
  dnlevelpathid: z.union([z.string(), z.number()]),
  dnminhydroseq: z.union([z.string(), z.number()]),
  uplevelpathid: z.union([z.string(), z.number()]),
  upminhydroseq: z.union([z.string(), z.number()]),
  divergenceflag: z.union([z.string(), z.number()]),
  terminalpathid: z.union([z.string(), z.number()]),
  visibilityfilter: z.string(),
  fcode_description: z.string(),
  ftype_description: z.string(),
  permanent_identifier: z.string(),
  wbarea_permanent_identifier: z.string(),
});

export const ReportPartHydrographyFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: HydrographyProperties.deepPartial(),
  geometry: GeometrySchema,
  source__id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  gnis_id: z.string().nullable(),
  streamlevel: z.preprocess(
    (value) => (value !== null ? Number(value) : value),
    z.number().nullable()
  ),
  streamorder: z.preprocess(
    (value) => (value !== null ? Number(value) : value),
    z.number().nullable()
  ),
  type: z.string().nullable(),
  transect_feature_type: z.literal('hydrography'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartHydrographyFeatureDTO = z.infer<
  typeof ReportPartHydrographyFeatureDTO
>;

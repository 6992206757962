import { z } from 'zod';
import { FilterModelSchema } from '../../../utils';
import { stringToNumber } from '../../../utils/string-to-number';

export const ReportsIndexQueryDTO = z.object({
  page: z
    .preprocess(
      (val) => (typeof val === 'string' ? stringToNumber(val) : val),
      z.number()
    )
    .default(1),
  pageSize: z
    .preprocess(
      (val) => (typeof val === 'string' ? stringToNumber(val) : val),
      z.number()
    )
    .default(100),
  sortBy: z.string().default('created_at'),
  order: z
    .string()
    .transform((value) => value.toUpperCase())
    .default('DESC'),
  search: z.string().optional(),
  sortModel: z
    .preprocess(
      (val) => (typeof val === 'string' ? JSON.parse(val) : val),
      z.array(
        z.object({
          colId: z.string(),
          sort: z.enum(['desc', 'asc', 'DESC', 'ASC']),
        })
      )
    )
    .default([]),
  filterModel: z
    .preprocess(
      (val) => (typeof val === 'string' ? JSON.parse(val) : val),
      z
        .object({
          project_name: FilterModelSchema.optional(),
          client_identifier: FilterModelSchema.optional(),
        })
        .passthrough()
    )
    .default({}),
  project__id: z.string().optional(),
  is_sales_demo: z
    .preprocess(
      (val) => (typeof val === 'string' ? val === 'true' : val),
      z.boolean()
    )
    .optional(),
});

export type ReportsIndexQueryDTO = z.infer<typeof ReportsIndexQueryDTO>;

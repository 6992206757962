import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  take,
} from 'rxjs';
import _isEqual from 'lodash/isEqual';

export const returnFromAction$ = <T>(observable: Observable<T>) => {
  return observable.pipe(
    map(() => {
      return;
    }),
    take(1)
  );
};

/**
 * @deprecated
 * @param currentState
 * @param newState
 */
export const updateState = <T>(
  currentState: BehaviorSubject<T>,
  newState: Partial<T>
) => {
  const finalState = {
    ...currentState.getValue(),
    ...newState,
  };
  currentState.next(finalState);
};

/**
 * @deprecated
 * @param state$
 * @param projector
 */
export const select = <R, S = unknown>(
  state$: BehaviorSubject<S>,
  projector: (state: S) => R
): Observable<R> => {
  return state$.pipe(
    map(projector),
    distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
  );
};

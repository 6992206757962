import { z } from 'zod';

export const PatchUserSelectedCatalogItemDTO = z
  .object({
    show_labels: z.boolean(),
    checked: z.boolean(),
    pinned: z.boolean(),
    sub_layers: z.array(
      z.object({
        label: z.string(),
        checked: z.boolean(),
      })
    ),
  })
  .partial();

export type PatchUserSelectedCatalogItemDTO = z.infer<
  typeof PatchUserSelectedCatalogItemDTO
>;

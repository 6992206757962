<div class="node-container" [class.is-leaf]="!hasChildren()">
  <button (click)="expanded = !expanded" type="button" *ngIf="hasChildren()">
    <i
      class="fas"
      [ngClass]="{ 'fa-chevron-right': !expanded, 'fa-chevron-down': expanded }"
    ></i>
  </button>
  <ng-content></ng-content>
</div>

<div
  [ngStyle]="{ display: expanded ? 'block' : 'none' }"
  class="children-container"
>
  <ng-content select="transect-nx-ui-tree-node"></ng-content>
</div>
